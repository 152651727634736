import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import Hero from "../../screens/hero";
import { useDispatch, useSelector } from "react-redux";
import {
    listProductSend,
    listProductReceive,
    listProductDetails,
} from "../../Redux/Actions/productActions";
import { toast } from "react-toastify";
import uniqid from "uniqid";
import { createOrder } from "../../Redux/Actions/orderActions";
import { ORDER_CREATE_RESET } from "../../Redux/Constants/orderConstants";
import Toast from "../LoadingError/toast";
import Message from "../LoadingError/error";
const ShopSection = () => {
    let history = useHistory();
    const [pmSelectSend, setShowSelectSend] = useState(false);

    const [pmSearchSend, setSearchSend] = useState("");
    const [pmSearchReceive, setSearchReceive] = useState("");

    const [pmSelectReceive, setShowSelectReceive] = useState(false);
    const [step, setStep] = useState(1);
    const [recipientWallet, setRecWallet] = useState("");
    const [recipientWalletError, setRecWalletError] = useState(false);
    const [errorSubmiting, setError] = useState(false);
    const [pmSelectTypeReceive, setTypeReceive] = useState("All");
    const dispatch = useDispatch();
    const [reload, setReload] = useState(true);
    const [sendCurr, setSendCurr] = useState("Bitcoin_BTC");
    const [receiveCurr, setRecieveCurr] = useState("Solana_SOL");
    const toastId = React.useRef(null);
    const Toastobjects = {
        pauseOnFocusLoss: false,
        draggable: false,
        pauseOnHover: false,
        autoClose: 2000,
    };
    const [senderMail, setMail] = useState("");
    const [senderMailError, setMailError] = useState(false);
    const [sendCurrCountError, setSendCountError] = useState(false);
    const [receiveCountError, setReceiveCountError] = useState(false);
    const [sendCurrCount, setSendCurrCount] = useState(1);
    const [receiveCount, setReceiveCount] = useState(0);
    const productDetails = useSelector((state) => state.productDetails);
    const productSendList = useSelector((state) => state.productSendList);
    const orderCreate = useSelector((state) => state.orderCreate);
    const productRecieveList = useSelector((state) => state.productRecieveList);
    const { loadingReceive, productsReceive } = productRecieveList;
    const { loadingSend, productsSend } = productSendList;
    const { loading, error, product } = productDetails;

    const { order, successOrderCreate, errorOrderCreate } = orderCreate;
    const pmReceiveHandler = (e) => {
        if (e.target.tagName === "DIV") {
            setShowSelectReceive((prev) => !prev);
            setShowSelectSend(false);
        }
    };
    const pmSendHandler = (e) => {
        if (e.target.tagName === "DIV") {
            setShowSelectSend((prev) => !prev);
            setShowSelectReceive(false);
        }
    };
    const sendCurrCountHandler = (e) => {
        setSendCountError(false);
        setSendCurrCount(e.target.value.replace("^[ 0-9]+$"));

        if (Number.isNaN(parseInt(e.target.value))) {
            setReceiveCount("0.00");
        } else {
            setReceiveCount(
                (parseFloat(e.target.value) * product.exchangeRate).toFixed(2)
            );
        }
    };
    const setReceiveCountHandler = (e) => {
        setReceiveCount(parseFloat(e.target.value));

        if (Number.isNaN(parseInt(e.target.value))) {
            setSendCurrCount("0.00");
        } else {
            setSendCurrCount(
                (parseFloat(e.target.value) / product.exchangeRate).toFixed(8)
            );
        }
    };

    const setMailHandler = (e) => {
        setMail(e.target.value);
        setMailError(false);
    };
    const setRecWalletHandler = (e) => {
        setRecWallet(e.target.value);
        setRecWalletError(false);
    };

    const toStep2 = () => {
        if (
            sendCurrCount >=
                productsSend.find((item) => item.nameSend === sendCurr)
                    .minAmountSend &&
            sendCurrCount <= product.maxAmountSend &&
            parseFloat(receiveCount) <=
                productsReceive.find((item) => item.nameReceive === receiveCurr)
                    .reserve
        ) {
            setStep(2);
        } else if (
            parseFloat(sendCurrCount) <
                productsSend.find((item) => item.nameSend === sendCurr)
                    .minAmountSend ||
            parseFloat(sendCurrCount) > product.maxAmountSend
        ) {
            if (
                parseFloat(sendCurrCount) <
                productsSend.find((item) => item.nameSend === sendCurr)
                    .minAmountSend
            ) {
                setSendCountError(true);
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(
                        `Dozwolona minimalna kwota do wysłania to ${
                            productsSend.find(
                                (item) => item.nameSend === sendCurr
                            ).minAmountSend
                        }`,
                        Toastobjects
                    );
                }
            } else if (parseFloat(sendCurrCount) > product.maxAmountSend) {
                setSendCountError(true);
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(
                        `Dozwolona maksymalna kwota do wysłania to ${product.maxAmountSend}`,
                        Toastobjects
                    );
                }
            }
        } else if (
            parseFloat(receiveCount) >
            productsReceive.find((item) => item.nameReceive === receiveCurr)
                .reserve
        ) {
            setReceiveCountError(true);
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    `Dozwolony maksymalny odbiór to. ${
                        productsReceive.find(
                            (item) => item.nameReceive === receiveCurr
                        ).reserve
                    }`,
                    Toastobjects
                );
            }
        } else {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    "Błąd przygotowania wniosku o wymianę. Proszę spróbować ponownie.",
                    Toastobjects
                );
            }
        }
    };

    const placeOrderHandler = (e) => {
        e.preventDefault();
        
        if (
            sendCurrCount >=
                productsSend.find((item) => item.nameSend === sendCurr)
                    .minAmountSend &&
            sendCurrCount <= product.maxAmountSend &&
            senderMail &&
            recipientWallet
        ) {
            console.log("createOrder func");
            dispatch(
                createOrder({
                    header: product.header,
                    name: product.name,
                    imageSend: product.imageSend,
                    imageReceive: product.imageReceive,
                    titleSend: product.titleSend,
                    titleReceive: product.titleReceive,
                    typeReceive: product.typeReceive,
                    receiveNumber: recipientWallet,
                    email: senderMail,
                    sendCount: parseFloat(sendCurrCount),
                    exchangeRate: product.exchangeRate,
                    symbolSend: product.symbolSend,
                    symbolReceive: product.symbolReceive,
                    
                })
            );
        }
    };

    const setSendCurrHandler = (e) => {
        setSendCurr(e);
        setShowSelectSend(false);
    };
    const setReceiveCurrHandler = (e) => {
        setRecieveCurr(e);
        setShowSelectReceive(false);
    };
    const submitHandler = (e) => {
        e.preventDefault();

        if (
            !loading &&
            productsSend.find((item) => item.nameSend === sendCurr)
                .minAmountSend <= sendCurrCount &&
            product.maxAmountSend >= sendCurrCount &&
            recipientWallet.length >= 16 &&
            senderMail.includes("@")
        ) {
            placeOrderHandler(e);
            setError(false);
        } else if (
            !(
                sendCurrCount >
                productsSend.find((item) => item.nameSend === sendCurr)
                    .minAmountSend
            )
        ) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    `Dozwolona minimalna kwota do wysłania to ${
                        productsSend.find((item) => item.nameSend === sendCurr)
                            .minAmountSend
                    }`,
                    Toastobjects
                );
            }

            setSendCountError(true);
        } else if (!(sendCurrCount < product.maxAmountSend)) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    `Dozwolona maksymalna kwota do wysłania to ${product.maxAmountSend}`,
                    Toastobjects
                );
            }
        } else if (recipientWallet.length < 16) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    "Proszę wpisać prawidłowy adres odbioru",
                    Toastobjects
                );
            }

            setRecWalletError(true);
        } else if (!senderMail.includes("@")) {
            if(localStorage.getItem("userInfo")){
                setMail(JSON.parse(localStorage.getItem("userInfo")).email);
            }


           else if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    "Proszę wpisać prawidłowy adres kontaktowy",
                    Toastobjects
                );
            }

            setMailError(true);
        } else {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    " Błąd przygotowania wniosku o wymianę. Proszę spróbować ponownie.",
                    Toastobjects
                );
            }
        }
    };
    useEffect(()=>{
        if(localStorage.getItem("userInfo")){
            setMail(JSON.parse(localStorage.getItem("userInfo")).email);
        }
    },[]);
    useEffect(() => {
        if (error) {
            setSendCurr("Bitcoin_BTC");
            setRecieveCurr("Solana_SOL");
        }
    }, [dispatch]);
    useEffect(() => {
        dispatch(listProductSend());
        dispatch(listProductReceive());
        dispatch(listProductDetails(`${sendCurr}_to_${receiveCurr}`));
    }, [dispatch, sendCurr, receiveCurr]);
    useEffect(() => {
        if (!loadingReceive && !loading) {
            setReceiveCount(product.exchangeRate * sendCurrCount);
        }
    }, [receiveCurr, product, loadingReceive, loading]);

    if (
        reload &&
        !loading &&
        typeof loading != "undefined" &&
        !loadingReceive
    ) {
        setReceiveCount(product.exchangeRate * sendCurrCount);
        setRecieveCurr(productsReceive[0].nameReceive);
        setReload(false);
    }
    useEffect(() => {
        console.log("effect");
        if (successOrderCreate) {
            history.push(`/order/${order._id}`);
            dispatch({ type: ORDER_CREATE_RESET });
        }
    }, [dispatch, history, successOrderCreate, order, errorOrderCreate]);

    useEffect(() => {
        console.log("new effect");
        if (errorOrderCreate) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(errorOrderCreate, Toastobjects);
            }
        }
    }, [dispatch, orderCreate]);

    if (step === 1) {
        return (
            <div
                className="main exchangeCont home-change step1"
                id="home-exchange"
            >
                <Hero />
                <Toast />

                <div className="main-right s1">
                    <div className="main-exchange-wrapper bg-opacity">
                        <div className="main-exchange" data-side="from">
                            <h3>Oddaj</h3>
                            <div
                                className="main-exchange-inputs exch-val-cont"
                                ps-side="from"
                            >
                                <div
                                    className="main-exchange-input"
                                    id="leftSumm"
                                >
                                    <input
                                        style={{
                                            border: `${
                                                sendCurrCountError
                                                    ? "1px solid red"
                                                    : ""
                                            }`,
                                        }}
                                        type="text"
                                        placeholder="Kwota"
                                        name="summ1"
                                        className="f-input"
                                        id="give_col"
                                        value={sendCurrCount}
                                        onChange={(e) =>
                                            sendCurrCountHandler(e)
                                        }
                                        min={
                                            !loading
                                                ? productsSend.find(
                                                      (item) =>
                                                          item.nameSend ===
                                                          sendCurr
                                                  ).minAmountSend
                                                : 0
                                        }
                                    />
                                    <span id="f-currency-from">
                                        {!loadingSend
                                            ? productsSend.find(
                                                  (item) =>
                                                      item.nameSend === sendCurr
                                              ).symbolSend
                                            : null}
                                    </span>
                                </div>
                                <div
                                    className={`main-exchange-valute ${
                                        pmSelectSend ? "active" : ""
                                    }`}
                                    onClick={(e) => pmSendHandler(e)}
                                >
                                    <div className="main-exchange-valute-wrapper">
                                        <img
                                            alt={
                                                !loadingSend
                                                    ? productsSend.find(
                                                          (item) =>
                                                              item.nameSend ===
                                                              sendCurr
                                                      ).cryptoNameSend
                                                    : "loading..."
                                            }
                                            src={
                                                !loadingSend
                                                    ? productsSend.find(
                                                          (item) =>
                                                              item.nameSend ===
                                                              sendCurr
                                                      ).imageSend
                                                    : ""
                                            }
                                            id="img_from"
                                        />
                                        <span id="ps-name-from">
                                            {!loadingSend
                                                ? productsSend.find(
                                                      (item) =>
                                                          item.nameSend ===
                                                          sendCurr
                                                  ).titleSend
                                                : ""}
                                        </span>
                                    </div>
                                    <div
                                        className="main-exchange-valute-search searchWrp"
                                        style={{
                                            display: `${
                                                pmSelectSend ? "block" : "none"
                                            }`,
                                        }}
                                    >
                                        <input
                                            type="text"
                                            className="search-input form-control"
                                            placeholder="Wprowadź nazwę waluty..."
                                            value={pmSearchSend}
                                            onChange={(e) =>
                                                setSearchSend(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className="main-exchange-list"
                                    style={{
                                        display: `${
                                            pmSelectSend ? "block" : "none"
                                        }`,
                                    }}
                                >
                                    <div className="main-exchange-list-top">
                                        <a
                                            href="/"
                                            className="psBtn active"
                                            data-group="all"
                                        >
                                            Wszystkie
                                        </a>
                                    </div>
                                    <div
                                        className="main-exchange-list-content val-list billPsList"
                                        data-direct="from"
                                    >
                                        {!loadingSend
                                            ? productsSend
                                                  .filter(
                                                      (item) =>
                                                          item.titleSend
                                                              .toLowerCase()
                                                              .includes(
                                                                  pmSearchSend.toLowerCase()
                                                              ) ||
                                                          item.symbolSend
                                                              .toLowerCase()
                                                              .includes(
                                                                  pmSearchSend.toLowerCase()
                                                              )
                                                  )
                                                  .map((item) => (
                                                      <div
                                                          key={uniqid()}
                                                          onClick={() =>
                                                              setSendCurrHandler(
                                                                  item.nameSend
                                                              )
                                                          }
                                                          className="item currency__item psItem"
                                                          data-seo={
                                                              item.cryptoNameSend
                                                          }
                                                          data-valute="BTC"
                                                      >
                                                          <div className="item-img">
                                                              <img
                                                                  alt={
                                                                      item.titleSend
                                                                  }
                                                                  src={
                                                                      item.imageSend
                                                                  }
                                                              />
                                                          </div>
                                                          <div className="item-title">
                                                              {item.titleSend}
                                                          </div>
                                                          <span>
                                                              {item.symbolSend}
                                                          </span>
                                                      </div>
                                                  ))
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-exchange" data-side="to">
                            <h3>Odbiór</h3>
                            <div
                                className="main-exchange-inputs exch-val-cont"
                                ps-side="to"
                            >
                                <div
                                    className="main-exchange-input"
                                    id="rightSumm"
                                >
                                    <input
                                        style={{
                                            border: `${
                                                receiveCountError
                                                    ? "1px solid red"
                                                    : ""
                                            }`,
                                        }}
                                        type="text"
                                        className="f-input"
                                        placeholder="Kwota"
                                        name="summ2"
                                        id="get_col"
                                        value={receiveCount}
                                        onChange={(e) =>
                                            setReceiveCountHandler(e)
                                        }
                                    />
                                    <span id="f-currency-to">
                                        {!loadingReceive
                                            ? productsReceive.find(
                                                  (item) =>
                                                      item.nameReceive ===
                                                      receiveCurr
                                              ).symbolReceive
                                            : null}
                                    </span>
                                </div>
                                <div
                                    className={`main-exchange-valute ${
                                        pmSelectReceive ? "active" : ""
                                    }`}
                                    onClick={(e) => pmReceiveHandler(e)}
                                >
                                    <div className="main-exchange-valute-wrapper">
                                        <img
                                            alt={
                                                !loadingReceive
                                                    ? productsReceive.find(
                                                          (item) =>
                                                              item.nameReceive ===
                                                              receiveCurr
                                                      ).titleReceive
                                                    : "loading..."
                                            }
                                            src={
                                                !loadingReceive
                                                    ? productsReceive.find(
                                                          (item) =>
                                                              item.nameReceive ===
                                                              receiveCurr
                                                      ).imageReceive
                                                    : ""
                                            }
                                            id="img_to"
                                        />
                                        <span id="ps-name-to">
                                            {!loadingReceive
                                                ? productsReceive.find(
                                                      (item) =>
                                                          item.nameReceive ===
                                                          receiveCurr
                                                  ).titleReceive
                                                : ""}
                                        </span>
                                    </div>
                                    <div
                                        className="main-exchange-valute-search searchWrp"
                                        style={{
                                            display: `${
                                                pmSelectReceive
                                                    ? "block"
                                                    : "none"
                                            }`,
                                        }}
                                    >
                                        <input
                                            type="text"
                                            className="search-input form-control"
                                            placeholder="Wprowadź nazwę waluty..."
                                            value={pmSearchReceive}
                                            onChange={(e) =>
                                                setSearchReceive(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className="main-exchange-list"
                                    style={{
                                        display: `${
                                            pmSelectReceive ? "block" : "none"
                                        }`,
                                    }}
                                >
                                    <div className="main-exchange-list-top">
                                        <a
                                            href="/#"
                                            className={`psBtn ${
                                                pmSelectTypeReceive === "All"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setTypeReceive("All")
                                            }
                                        >
                                            Wszystkie
                                        </a>
                                        <a
                                            href="/#"
                                            className={`psBtn ${
                                                pmSelectTypeReceive === "crypto"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setTypeReceive("crypto")
                                            }
                                        >
                                            Kryptowaluty
                                        </a>
                                        <a
                                            href="/#"
                                            className={`psBtn ${
                                                pmSelectTypeReceive === "bank"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setTypeReceive("bank")
                                            }
                                        >
                                            Banki
                                        </a>
                                        <a
                                            href="/#"
                                            className={`psBtn ${
                                                pmSelectTypeReceive === "eps"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setTypeReceive("eps")
                                            }
                                        >
                                            EPS
                                        </a>
                                    </div>
                                    <div
                                        className="main-exchange-list-content val-list billPsList"
                                        data-direct="to"
                                    >
                                        {!loadingReceive
                                            ? productsReceive
                                                  .filter((item) => {
                                                      switch (
                                                          pmSelectTypeReceive
                                                      ) {
                                                          case "bank":
                                                              if (
                                                                  item.banking
                                                              ) {
                                                                  return item;
                                                              }
                                                              break;
                                                          case "eps":
                                                              if (item.eps) {
                                                                  return item;
                                                              }
                                                              break;
                                                          case "crypto":
                                                              if (
                                                                  !item.efs &&
                                                                  !item.banking &&
                                                                  !item.fiat
                                                              ) {
                                                                  return item;
                                                              }
                                                              break;
                                                          default:
                                                              return item;
                                                      }
                                                  })
                                                  .filter(
                                                      (item) =>
                                                          item.titleReceive
                                                              .toLowerCase()
                                                              .includes(
                                                                  pmSearchReceive.toLowerCase()
                                                              ) ||
                                                          item.symbolReceive
                                                              .toLowerCase()
                                                              .includes(
                                                                  pmSearchReceive.toLowerCase()
                                                              )
                                                  )
                                                  .map((item) => (
                                                      <div
                                                          key={uniqid()}
                                                          onClick={() =>
                                                              setReceiveCurrHandler(
                                                                  item.nameReceive
                                                              )
                                                          }
                                                          className="item currency__item psItem"
                                                          data-group="crypto"
                                                          data-seo={
                                                              item.cryptoNameSend
                                                          }
                                                      >
                                                          <div className="item-img">
                                                              <img
                                                                  alt={
                                                                      item.titleReceive
                                                                  }
                                                                  src={
                                                                      item.imageReceive
                                                                  }
                                                              />
                                                          </div>
                                                          <div className="item-title">
                                                              {
                                                                  item.titleReceive
                                                              }
                                                          </div>
                                                          <span>
                                                              {
                                                                  item.symbolReceive
                                                              }
                                                          </span>
                                                      </div>
                                                  ))
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-exchange-bottom-info">
                            <div className="col min-summa">
                                <div className="col-wrap from_min_amount">
                                    <span>Kwota min.</span>
                                    <strong id="mainMin" ps-mincol="give_col">
                                        {!loadingSend
                                            ? productsSend.find(
                                                  (item) =>
                                                      item.nameSend === sendCurr
                                              ).minAmountSend
                                            : null}{" "}
                                        {!loadingSend
                                            ? productsSend.find(
                                                  (item) =>
                                                      item.nameSend === sendCurr
                                              ).symbolSend
                                            : null}
                                    </strong>
                                </div>
                            </div>
                            <div className="col course">
                                <div className="col-wrap">
                                    <span>Kurs wymiany</span>
                                    <strong id="h-course">
                                        {!loading
                                            ? !error
                                                ? `1 ${product.symbolSend} - ${product.exchangeRate} ${product.symbolReceive}`
                                                : null
                                            : null}
                                    </strong>
                                </div>
                            </div>
                            <div className="col reserve">
                                <div className="col-wrap" id="h-reserve">
                                    <span>Rezerwa</span>
                                    <strong>
                                        {" "}
                                        {!loadingReceive
                                            ? productsReceive.find(
                                                  (item) =>
                                                      item.nameReceive ===
                                                      receiveCurr
                                              ).reserve
                                            : null}{" "}
                                        {!loadingReceive
                                            ? productsReceive.find(
                                                  (item) =>
                                                      item.nameReceive ===
                                                      receiveCurr
                                              ).symbolReceive
                                            : null}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div className="main-exchange-bottom">
                            <label className="label-check">
                                <input
                                    type="checkbox"
                                    id="go2s2"
                                    defaultChecked
                                />
                                <span />
                                Zgadzam się z{" "}
                                <a href="/rules/"> zasadami wymiany</a>
                            </label>
                            <input
                                type="button"
                                defaultValue="Wymienić"
                                id="showProps"
                                className="btn"
                                onClick={() => toStep2()}
                            />
                        </div>
                        <p className="showerror">{errorSubmiting}</p>
                    </div>
                </div>
            </div>
        );
    } else if (step === 2) {
        return (
            <div
                className="main exchangeCont home-change step2"
                id="home-exchange"
            >
                <Toast />

                <div className="main-left s1">
                    <h1>Usługa konwersji walut cyfrowych</h1>
                    <div className="main-preim-items">
                        <div className="item">
                            <div className="item-icon">
                                <img
                                    src="/res/dotsatoshi/img/main-icon-1.svg"
                                    alt="i1"
                                />
                            </div>
                            <p>Nie wymaga rejestracji ani konta</p>
                        </div>
                        <div className="item">
                            <div className="item-icon">
                                <img
                                    src="/res/dotsatoshi/img/main-icon-2.svg"
                                    alt="i2"
                                />
                            </div>
                            <p>Twoje bezpieczeństwo jest naszym celem</p>
                        </div>
                        <div className="item">
                            <div className="item-icon">
                                <img
                                    src="/res/dotsatoshi/img/main-icon-3.svg"
                                    alt="i3"
                                />
                            </div>
                            <p>Korzyści wynikające z programów współpracy</p>
                        </div>
                    </div>
                </div>

                <div className="main-exchange-wrapper bg-opacity s2">
                    <h1>Usługa konwersji walut cyfrowych</h1>
                    <div
                        className="change-info errorTxt text-center"
                        id="calcError"
                    />
                    <div id="props" className="f-home-fields">
                        {" "}
                        <div className="col-md-6 col-xl-4 ff-removable">
                            <div className="form-group mb-4" data-psname>
                                <label>E-mail lub Telegram</label>
                                <div className="form-field">
                                    <input
                                        style={{
                                            border: `${
                                                senderMailError
                                                    ? "1px solid red"
                                                    : ""
                                            }`,
                                        }}
                                        id="id_email"
                                        
                                        placeholder={JSON.parse( localStorage.getItem("userInfo"))?JSON.parse( localStorage.getItem("userInfo")).email:"login@yahoo.com | @login"}
                                        className="form-control p-form-control form-control--add f-input"
                                        value={JSON.parse( localStorage.getItem("userInfo"))?JSON.parse( localStorage.getItem("userInfo")).email:senderMail}
                                        onChange={(e) => setMailHandler(e)}
                                        name="email"
                                        type="text"
                                        readOnly={JSON.parse( localStorage.getItem("userInfo"))?true:false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 ff-removable">
                            <div
                                className="form-group mb-4"
                                data-psname="USDT TRC20"
                            >
                                <label>Portfel do odbioru</label>
                                <div className="form-field">
                                    <input
                                        style={{
                                            border: recipientWalletError
                                                ? "1px solid red"
                                                : "",
                                        }}
                                        id="id_to_acc"
                                        placeholder="Przykład: TeiVnJ7lqxI8upKSOt4QLZWQTZbE9UrD5e"
                                        className="form-control p-form-control form-control--add f-input"
                                        value={recipientWallet}
                                        onChange={(e) => setRecWalletHandler(e)}
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 ff-removable">
                            <div className="form-group mb-4" data-psname>
                                <label>Kupon</label>
                                <div className="form-field">
                                    <input
                                        id="id_coupon"
                                        placeholder="Kod kuponu (jeśli jest dostępny)"
                                        className="form-control p-form-control form-control--add f-input"
                                        name="coupon"
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                        <br style={{ clear: "both" }} />
                    </div>
                    <div
                        className="change__block-footer"
                        data-propsstatus="success"
                        id="homeChangeBtns"
                    >
                        <button
                            className="btn btn-white"
                            id="cancelExchange"
                            onClick={() => setStep(1)}
                        >
                            Z powrotem do tyłu
                        </button>
                        <button
                            className="btn btn-primary"
                            id="goto-payment"
                            onClick={(e) => submitHandler(e)}
                        >
                            Rozpocząć wymianę
                        </button>{" "}
                        <p className="showerror">{errorSubmiting}</p>
                        <div className="gotoPaymAgree">
                            Klikając "Rozpocznij wymianę" zgadzasz się na{" "}
                            <a href="/rules/" target="_blank">
                                zasady wymiany
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ShopSection;
