import React, { useState, useEffect } from "react";
import { login } from "../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import mobxStore from "../../mobx/mobxStore";
import Toast from "../LoadingError/toast";
import { toast } from "react-toastify";
const SignIn = ({ location, history }) => {
    const [userLoginValue, setLoginValue] = useState("");
    const [password, setPassword] = useState("");
    const { variables, signInChange,signUpChange,forgotChange } = mobxStore;
    const dispatch = useDispatch();
    const Toastobjects = {
        pauseOnFocusLoss: false,
        draggable: false,
        pauseOnHover: false,
        autoClose: 2000,
    };
    const toastId = React.useRef(null);
console.log(location);
     const redirect = location.search ? location.search.split("=")[1] : "/";
console.log("redirect",redirect);
    const userLogin = useSelector((state) => state.userLogin);
    const { error, userInfo } = userLogin;

    useEffect(() => {
        if (userInfo) {
            // console.log(redirect);
            window.location.href=redirect;
        }
    }, [userInfo, history, redirect]);
    if(error){
        if (!toast.isActive(toastId.current)) {
			toastId.current = toast.error(
				error,
			    Toastobjects
			);
		    } 
    }
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(login(userLoginValue, password));
    };
    return (
        <React.Fragment>
            <Toast/>
            <div
                className="mfp-bg mfp-ready"
                style={{ height: "3384px", position: "absolute" }}
            />

            <div
                className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                tabIndex={-1}
                style={{
                    top: "0px",
                    position: "absolute",
                    height: "1111px",
                }}
            >
                <div className="mfp-container mfp-inline-holder">
                    <div className="mfp-content">
                        <div
                            className="modal vr-modal login-modal"
                            id="login-modal"
                        >
                            <div className="modal-wrapper" id="signin">
                                <h2 className="modal-title">Logowanie</h2>
                                <div id="auth-form">
                                    <div className="input-group form-group">
                                        <input
                                            type="text"
                                            className="form-control f-input"
                                            name="email"
                                            id="emailInp"
                                            autoComplete="off"
                                            placeholder="Wprowadź e-mail"
                                            onChange={(e) =>
                                                setLoginValue(e.target.value)
                                            }
                                            value={userLoginValue}
                                        />
                                    </div>
                                    <div className="input-group form-group">
                                        <input
                                            className="form-control f-input"
                                            name="password"
                                            type="password"
                                            autoComplete="off"
                                            placeholder="Wprowadź hasło"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            value={password}
                                        />
                                        <input
                                            type="hidden"
                                            className="f-input"
                                            name="action"
                                            defaultValue="authorize"
                                        />
                                    </div>
                                    <div className="form-check">
                                        <a
                                            href="#password-reset-modal"
                                            className="popup-modal"
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                forgotChange(true);
                                                signInChange(false);
                                            }}
                                        >
                                            Zapomniałeś hasła?
                                        </a>
                                    </div>
                                    <div className="user-security-check py-2" />

                                    <div className="form-bottom">
                                        <button
                                            onClick={(e)=>submitHandler(e)}
                                            className="btn"
                                            id="auth-form-btn"
                                        >
                                            Logowanie
                                        </button>
                                        <a
                                            href="#register-modal"
                                            className="btn btn-white popup-modal"
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                signInChange(false);
                                                signUpChange(true);
                                            }}
                                        >
                                            Rejestracja
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <button
                                title="Close (Esc)"
                                type="button"
                                className="mfp-close"
                                onClick={() => signInChange(false)}
                            >
                                ×
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SignIn;
