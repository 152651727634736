import {
    ORDER_CREATE_FAIL,
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,ORDER_CREATE_RESET,
    ORDER_DETAILS_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_LIST_MY_FAIL,
    ORDER_LIST_MY_REQUEST,
    ORDER_LIST_MY_SUCCESS,
    ORDER_PAY_FAIL,
    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
} from "../Constants/orderConstants";
import axios from "axios";
import { logout } from "./userActions";
import { serverLink } from "../../App";
//const serverLink = "http://localhost:5000"
// CREATE ORDER
export const createOrder = (order) => async (dispatch, getState) => {
    try {
        
        dispatch({ type: ORDER_CREATE_REQUEST });
        let config;
        const {
            userLogin: { userInfo },
        } = getState();
        console.log("createOrder");
        if(userInfo){
             config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };
        }
        else{
            
            throw new Error("Aby utworzyć wniosek o wymianę, należy zalogować się na swoje konto" )
        }
        
        const { data } = await axios.post(
            `${serverLink}/api/orders/secure`,
            order,
            config
        );

        dispatch({ type: ORDER_CREATE_SUCCESS, payload: data });
    } catch (error) {
        
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
                console.log("createOrder error:", message);
                
                dispatch({
                    type: ORDER_CREATE_FAIL,
                    payload: message,
                });
    }
};

// ORDER DETAILS
export const getOrderDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: ORDER_DETAILS_REQUEST });

        const { data } = await axios.get(`${serverLink}/api/orders/${id}`);
        dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload: message,
        });
    }
};

// ORDER PAY
export const payOrder = (orderId) => async (dispatch) => {
    try {
        dispatch({ type: ORDER_PAY_REQUEST });

        const { data } = await axios.post(
            `${serverLink}/api/orders/${orderId}/promiss`
        );
        dispatch({ type: ORDER_PAY_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;

        dispatch({
            type: ORDER_PAY_FAIL,
            payload: message,
        });
    }
};

// USER ORDERS
export const listMyOrders = () => async (dispatch, getState) => {
    try {
        dispatch({ type: ORDER_LIST_MY_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`${serverLink}/api/orders/`, config);

        dispatch({ type: ORDER_LIST_MY_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: ORDER_LIST_MY_FAIL,
            payload: message,
        });
    }
};
