import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Toast from "../LoadingError/toast";
import { toast } from "react-toastify";
import Message from "../LoadingError/error";
import { updateUserProfile,getUserDetails } from "../../Redux/Actions/userActions";
const Settings = () => {
        const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
    const { loading, success, error } = userUpdateProfile;
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [oldPassword,setOldPassword]=useState("");
    const toastId = React.useRef(null);
    const Toastobjects = {
        pauseOnFocusLoss: false,
        draggable: false,
        pauseOnHover: false,
        autoClose: 2000,
    };
    const userDetails = useSelector((state) => state.userDetails);
    const { loadingUser, user } = userDetails;
    const dispatch = useDispatch();

    useEffect(() => {
        if (success) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success("Success", Toastobjects);
            }
        } else if (error) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(error, Toastobjects);
            }
        }
    }, [userUpdateProfile]);



    const submitHandler = (e) => {
        e.preventDefault();
        // Password match
        if (password !== confirmPassword) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    "Password does not match",
                    Toastobjects
                );
            }
        } else {
            dispatch(updateUserProfile({ id: user._id, password: password }));
        }
    };

    return (
        <div className="content">
            <Toast />{" "}
            {error && <Message variant="alert-danger"> {error} </Message>}
            <div className="content">
                <div className="container">
                    <div className="content-top cabinet-top">
                        <h1 className="title">Szafka osobista</h1>
                        <div className="cabinet-top-right">
                            <div className="item">
                            Twój rabat <span>0%</span>
                            </div>
                            <div className="item">
                            Saldo poleceń <span>0$</span>
                            </div>
                        </div>
                    </div>
                    <div className="content-wrapper cabinet-content bg-opacity">
                        <div className="cabinet-content-wrapper">
                            <div className="cabinet-content-left">
                                <div
                                    className="cabinet-settings"
                                    id="settings-entry"
                                >
                                    <h2>Ustawienia</h2>
                                    <div className="settingsWrp">
                                        <div
                                            className="form-left"
                                            id="changePassform"
                                        >
                                            <h3>Zmiana hasła</h3>
                                            {/* <div className="input-group">
                                                <label>Текущий пароль</label>
                                                <input
                                                    className="password form-control f-input"
                                                    type="password"
                                                    id="name1"
                                                    name="oldpass"
                                                    autoComplete="off"
                                                    onChange={(e)=>setOldPassword(e.target.value)}
                                                    value={oldPassword}
                                                />
                                            </div> */}
                                            <div className="input-group">
                                                <label>Nowe hasło</label>
                                                <input
                                                    type="password"
                                                    id="name2"
                                                    className="password form-control f-input"
                                                    name="newpass"
                                                    autoComplete="off"
                                                    onChange={(e)=>setPassword(e.target.value)}
                                                    value={password}
                                                />
                                            </div>
                                            <div className="input-group">
                                                <label>
                                                Ponownie nowe hasło
                                                </label>
                                                <input
                                                    type="password"
                                                    id="name3"
                                                    className="password form-control f-input"
                                                    name="repeatpass"
                                                    autoComplete="off"
                                                    onChange={(e)=>setConfirmPassword(e.target.value)}
                                                    value={confirmPassword}
                                                />
                                            </div>
                                            <button
                                                className="btn"
                                                type="button"
                                                id="changePass"
                                                onClick={(e)=>submitHandler(e)}
                                            >
                                                Zapisz
                                            </button>
                                        </div>
                                        <div className="form-right">
                                            <h3>Inne ustawienia</h3>
                                            <div className="cabinet-other-settings">
                                                <label className="pl55">
                                                    <input
                                                        className="f-input"
                                                        id="subscription"
                                                        name="subscription"
                                                        type="checkbox"
                                                        defaultChecked
                                                    />
                                                    <span />
                                                    Zapisz się do newslettera{" "}
                                                </label>
                                                <div id="subscriptionResult" />
                                            </div>
                                            <br />
                                            <h3>Bezpieczeństwo</h3>
                                            <div className="cabinet-other-settings securitySettings">
                                                <div className="rowElem">
                                                    <label>
                                                    Kod weryfikacyjny e-mail:
                                                    </label>
                                                    <div className="elemBtn">
                                                        <button
                                                            className="btn btn-secondary btn-small x-security-settings-change"
                                                            data-provider="email"
                                                            data-action_id="change_security"
                                                            onClick={()=>{
                                                                if (!toast.isActive(toastId.current)) {
                                                                    toastId.current = toast.error("Nie ma możliwości wyłączenia tej metody zabezpieczeń", Toastobjects);
                                                                }
                                                            }}
                                                        >
                                                            Wyłącz
                                                        </button>
                                                    </div>
                                                </div>{" "}
                                                
                                               
                                                
                                                <div id="security_form" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cabinet-content-right">
                                <div className="cabinet-menu">
                                    <a href="/account/orders/" className>
                                    Historia transakcji
                                    </a>
                                    <a href="/account/referals/" className>
                                    Program skierowań
                                    </a>
                                    <a href="/account/api/" className>
                                        API
                                    </a>
                                    <a
                                        href="/account/settings/"
                                        className="active"
                                    >
                                        Ustawienia
                                    </a>
                                    <a href="/account/verification/" className>
                                    Weryfikacja
                                    </a>
                                    <a href="/account/balance/" className>
                                    Równowaga
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;
