import moment from "moment";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import Moment from "react-moment";

import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails, payOrder } from "../Redux/Actions/orderActions";
function useInterval(callback, delay) {
    const intervalRef = React.useRef(null);
    const savedCallback = React.useRef(callback);
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    React.useEffect(() => {
        const tick = () => savedCallback.current();
        if (typeof delay === "number") {
            intervalRef.current = window.setInterval(tick, delay);
            return () => window.clearInterval(intervalRef.current);
        }
    }, [delay]);
    return intervalRef;
}
const OrderScreen = ({ match }) => {
    let now = new Date().getTime();

    const [timeToPay, setTimeToPay] = useState({ minutes: 15, seconds: 0 });
    const orderId = match.params.id;
    const dispatch = useDispatch();
    const [timeDiff, setDiff] = useState();
    const [startTime, setStart] = useState();
    const orderDetails = useSelector((state) => state.orderDetails);
    const { order, loading } = orderDetails;
    const orderPay = useSelector((state) => state.orderPay);
    const { successPay } = orderPay;
    const [walletCopied,setWalletCopied]=useState(false);
    const [amountCopied,setAmountCopied]=useState(false);
    useEffect(() => {
        if (successPay) {
            dispatch(getOrderDetails(orderId));
        }
    }, [dispatch, orderId, successPay]);
    useEffect(() => {
        if (!order) {
            dispatch(getOrderDetails(orderId));
        }
    }, [dispatch, orderId, order]);

    useInterval(() => {
        if (!loading) {
            if (order.isDeleted) {
                setDiff(moment.duration(1800000));
            } else {
                setStart(new Date(order.createdAt).getTime());
                setDiff(moment.duration(now - startTime));
            }
        }
    }, 1 * 1000);
    const walletCopiedHandler=()=>{
        setWalletCopied(true);
        console.log(walletCopied);
        setTimeout(()=>{
            setWalletCopied(false);
        },2000)
    }
    const amountCopiedHandler=()=>{
        setAmountCopied(true);
        
        setTimeout(()=>{
            setAmountCopied(false);
        },2000)
    }
    const iPaid = () => {
        dispatch(payOrder(orderId));
    };
    if (!loading && order.promisedPayment) {
        return (
            <div className="content">
                <div
                    className="container"
                    id="status"
                    data-order-location="frozen"
                    data-status="frozen"
                    data-extend-status="frozen"
                    data-id={16743328645746}
                >
                    <div className="content-top">
                        <div className="order-top order-top-begin">
                            <div className="left">
                                <h1 className="title">
                                    Zgłoszenie #{order._id}{" "}
                                    {order.isPaid
                                        ? order.isError
                                            ? ". Pomyłka!"
                                            : ". Zrobione!"
                                        : "Zostało przyjęte do weryfikacji!"}
                                </h1>
                                <p>
                                    {order.isError ? (
                                        <a
                                            href="/contacts"
                                            className="showerror"
                                            style={{ fontSize: "1.25rem" }}
                                        >
                                            Skontaktuj się z obsługą strony!
                                        </a>
                                    ) : order.isPaid ? (
                                        "Środki na ten cel otrzymali. Poczekaj"
                                    ) : (
                                        "Sprawdzanie dowodów kasowych"
                                    )}
                                </p>
                            </div>
                            <div className="right">
                                <div className="order-top-waiting"></div>
                            </div>
                        </div>
                    </div>
                    <div className="content-wrapper order-wrapper bg-opacity">
                        <div className="order-wrapper-top">
                            <div className="order-directions">
                                <div className="order-directions-item">
                                    <h3>Oddaj </h3>
                                    <div className="order-directions-info">
                                        <div className="order-directions-info-left">
                                            {order.sendCount}{" "}
                                            <span>{order.symbolSend}</span>
                                        </div>
                                        <div className="order-directions-info-right">
                                            <img
                                                src={order.imageSend}
                                                alt={order.titleSend}
                                                className="miniIcon"
                                            />
                                            <strong>{order.titleSend}</strong>
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src="/res/dotsatoshi/img/direction.svg"
                                    alt="direction"
                                    className="directions-img"
                                />
                                <div className="order-directions-item">
                                    <h3>Odbiór</h3>
                                    <div className="order-directions-info">
                                        <div className="order-directions-info-left">
                                            {order.exchangeRate *
                                                order.sendCount}{" "}
                                            <span>{order.symbolReceive}</span>
                                        </div>
                                        <div className="order-directions-info-right">
                                            <img
                                                src={order.imageReceive}
                                                alt={order.titleReceive}
                                                className="miniIcon"
                                            />
                                            <strong>
                                                {order.titleReceive}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="order-wrapper-bottom">
                            <div className="order-step">
                                <div className="order-step-item">
                                    <div className="right">
                                        <h4>
                                            {order.isPaid
                                                ? order.isError
                                                    ? "Błąd!"
                                                    : "Otrzymane środki!"
                                                : "Czekanie na fundusze"}
                                        </h4>
                                        <p></p>

                                        {order.isPaid ? (
                                            order.isError ? (
                                                <div id="tx_confirmation">
                                                    <div className="txc-title">
                                                        Prosimy o kontakt z
                                                        obsługą strony
                                                        internetowej
                                                    </div>
                                                </div>
                                            ) : (
                                                <div id="tx_confirmation">
                                                    <div className="txc-title">
                                                        Poczekaj na przybycie
                                                        funduszy
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div id="tx_confirmation">
                                                <div className="txc-title">
                                                    <div className="logo">
                                                        <img
                                                            src="/res/dotsatoshi/img/logo.svg"
                                                            alt="logo"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <p />
                                    </div>
                                </div>
                                <div className="order-step-item">
                                    <div
                                        className="order-bottom-step-buttons"
                                        style={{ justifyContent: "center" }}
                                    >
                                        <a href="/" className="btn">
                                            Nowa giełda
                                        </a>
                                    </div>
                                </div>
                                <div className="order-step-item bottom-text">
                                    <p>
                                        <a href="/contacts/" target="_blank">
                                            Zespół obsługi klienta{" "}
                                        </a>
                                        pomoże Ci, jeśli masz jakiekolwiek
                                        problemy lub pytania.
                                    </p>
                                </div>
                            </div>{" "}
                        </div>
                    </div>
                </div>

                <style
                    dangerouslySetInnerHTML={{
                        __html: "\n  .notification-container{\n    background-color: #eee;\n    position: fixed;\n    right: 0;\n    top: 0;\n    width: 100%;\n    max-width: 440px;\n    height: 100%;\n    z-index: 1000;\n    box-shadow: 0 0 10px rgba(0,0,0,.5);\n    display: flex;\n    flex-direction: column;\n  }\n  .notification-header{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px;\n  }\n  .notification-close{\n    font-size: 20px;\n    cursor: pointer;\n    padding: 5px;\n  }\n  .notification-element{\n    padding: 20px;\n    background-color: #fff;\n    margin: 10px;\n  }\n",
                    }}
                />
            </div>
        );
    }
    return (
        <div className="content">
            <div
                className="container"
                id="status"
                data-order-location="preliminary"
                data-status="preliminary"
                data-extend-status="preliminary"
                data-id={16743315731182}
            >
                <div className="content-top">
                    <div className="order-top order-top-begin">
                        <div className="left">
                            <h1 className="title">
                                Aplikacja #{!loading ? order._id : null}{" "}
                                {!loading
                                    ? order.isDeleted
                                        ? "usunięty!"
                                        : "stworzony przez!"
                                    : null}
                            </h1>
                            <p>
                                {!loading ? (
                                    order.isDeleted ? (
                                        <span>
                                            Wniosek został usunięty. Jeśli
                                            uważasz, że wystąpił błąd,
                                            skontaktuj się z nami.{" "}
                                            <a href="/contacts">
                                                Informacja zwrotna
                                            </a>
                                        </span>
                                    ) : (
                                        "Postępuj zgodnie z instrukcjami, aby zakończyć wymianę"
                                    )
                                ) : null}
                            </p>
                        </div>
                        <div className="right">
                            <div className="order-top-time">
                                Dostępne od:&nbsp;{" "}
                                <span id="lasttime">
                                    <span>
                                        {!loading
                                            ? order.isDeleted
                                                ? "00"
                                                : timeDiff
                                                ? 30 - timeDiff._data.minutes <
                                                  10
                                                    ? `0${
                                                          30 -
                                                          timeDiff._data.minutes
                                                      }`
                                                    : 30 -
                                                      timeDiff._data.minutes
                                                : null
                                            : null}
                                        :
                                    </span>
                                    <span>
                                        {!loading
                                            ? order.isDeleted
                                                ? "00"
                                                : timeDiff
                                                ? 60 - timeDiff._data.seconds <
                                                  10
                                                    ? `0${
                                                          60 -
                                                          timeDiff._data.seconds
                                                      }`
                                                    : 60 -
                                                      timeDiff._data.seconds
                                                : null
                                            : null}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-wrapper order-wrapper bg-opacity">
                    <div className="order-wrapper-top">
                        <div className="order-directions">
                            <div className="order-directions-item">
                                <h3>Oddaj </h3>
                                <div className="order-directions-info">
                                    <div className="order-directions-info-left">
                                        {!loading ? order.sendCount : null}
                                        <span>
                                            {!loading ? order.symbolSend : null}
                                        </span>
                                    </div>
                                    <div className="order-directions-info-right">
                                        <img
                                            src={
                                                !loading ? order.imageSend : ""
                                            }
                                            alt={
                                                !loading
                                                    ? order.titleSend
                                                    : null
                                            }
                                            className="miniIcon"
                                        />
                                        <strong>
                                            {!loading ? order.titleSend : null}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <img
                                src="/res/dotsatoshi/img/direction.svg"
                                alt="direction"
                                className="directions-img"
                            />
                            <div className="order-directions-item">
                                <h3>Odbiór</h3>
                                <div className="order-directions-info">
                                    <div className="order-directions-info-left">
                                        {!loading
                                            ? order.sendCount *
                                              order.exchangeRate
                                            : null}{" "}
                                        <span>
                                            {!loading
                                                ? order.symbolReceive
                                                : null}
                                        </span>
                                    </div>
                                    <div className="order-directions-info-right">
                                        <img
                                            src={
                                                !loading
                                                    ? order.imageReceive
                                                    : ""
                                            }
                                            alt={
                                                !loading
                                                    ? order.titleReceive
                                                    : null
                                            }
                                            className="miniIcon"
                                        />
                                        <strong>
                                            {!loading
                                                ? order.titleReceive
                                                : null}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="order-wrapper-bottom">
                        <div className="order-step">
                            {!loading ? (
                                order.isDeleted ? null : (
                                    <React.Fragment>
                                        <div className="order-step-item">
                                            <div className="left">
                                                <span className="uppercase">
                                                    Krok 1
                                                </span>
                                            </div>
                                            <div className="right">
                                                <h4>
                                                    Zaloguj się do swojego
                                                    oprogramowania klienckiego{" "}
                                                    {!loading
                                                        ? order.titleSend
                                                        : null}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="order-step-item">
                                            <div className="left">
                                                <span className="uppercase">
                                                    Krok 2
                                                </span>
                                            </div>
                                            <div className="right">
                                                <h4>
                                                    Dokonaj przelewu korzystając
                                                    z danych
                                                </h4>
                                            </div>
                                        </div>
                                        <form>
                                            <div className="form-wrapper">
                                                <div className="form-left">
                                                    <div className="input-group">
                                                        <label>
                                                            Adres{" "}
                                                            {!loading
                                                                ? order.titleSend
                                                                : null}
                                                        </label>
                                                        <CopyToClipboard
                                                            children={
                                                                <div className="flex cursor-grab">
                                                                    <img
                                                                        src={
                                                                            !loading
                                                                                ?  order.imageSend
                                                                                : ""
                                                                        }
                                                                        alt="cryptoIcon"
                                                                    />
                                                                    <input
                                                                        type="text"
                                                                        defaultValue={
                                                                            !loading
                                                                                ? order.wallet
                                                                                : null
                                                                        }
                                                                        readOnly
                                                                        autoComplete="off"
                                                                    />
                                                                    <span
                                                                        className={`fCopyBtn ${walletCopied?"copied":""}`}
                                                                        text2copy={
                                                                            !loading
                                                                                ? order.wallet
                                                                                : null
                                                                        }
                                                                        
                                                                    />
                                                                </div>
                                                            }
                                                            text={
                                                                !loading
                                                                    ? order.wallet
                                                                    : null
                                                            }
                                                            onCopy={()=>walletCopiedHandler()}
                                                        />
                                                    </div>
                                                    <div className="input-group">
                                                        <label>Na kwotę</label>
                                                        <CopyToClipboard
                                                            children={
                                                                <div className="flex cursor-grab">
                                                                    <img
                                                                        src={
                                                                            !loading
                                                                                ? order.imageSend
                                                                                : ""
                                                                        }
                                                                        alt="cryptoIcon"
                                                                    />
                                                                    <input
                                                                        type="text"
                                                                        defaultValue={
                                                                            !loading
                                                                                ? order.sendCount
                                                                                : null
                                                                        }
                                                                        readOnly
                                                                        autoComplete="off"
                                                                    />
                                                                    <p>
                                                                        {!loading
                                                                            ? order.symbolSend
                                                                            : null}
                                                                    </p>
                                                                    <span
                                                                        className={`fCopyBtn ${amountCopied?"copied":""}`}
                                                                        text2copy={
                                                                            !loading
                                                                                ? order.sendCount
                                                                                : null
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                            text={
                                                                !loading
                                                                    ? order.sendCount
                                                                    : null
                                                            }
                                                            onCopy={amountCopiedHandler}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div
                                            className="order-step-item"
                                            style={{ marginTop: "30px" }}
                                        >
                                            <ul
                                                style={{
                                                    listStyle: "none",
                                                    background: "#396CF5",
                                                    color: "white",
                                                    border: "solid 1px #eec351",
                                                    padding: "10px 20px",
                                                    margin: 0,
                                                }}
                                            >
                                                <li>
                                                    Upewnij się, że sieć
                                                    odpowiada sieci wybranej
                                                    przez odbiorcę, aby uniknąć
                                                    utraty funduszy
                                                </li>
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                )
                            ) : null}

                            <div className="order-step-item">
                                <div className="left">
                                    <span className="uppercase">Krok 3</span>
                                </div>
                                <div className="right">
                                    <div className="order-step-buttons">
                                        <button
                                            className="btn"
                                            onClick={() => iPaid()}
                                            id="I-paid"
                                            data-wait="Ждите"
                                            data-id={16743315731182}
                                        >
                                            ZAPŁACIŁEM{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>{" "}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderScreen;
