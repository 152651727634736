import React from "react";

const Warranties = () => {
    return (<div className="content">
        <div className="container">
            <div className="content-top ">
                <h1 className="title">Gwarancje niezawodności</h1>
                <p>Legalny serwis - niezawodna wymiana</p>
            </div>
            <div className="content-wrapper bg-opacity guarantee-wrapper">
                <div className="guarantee-wrapper-top">
                    <div className="guarantee-wrapper-left">

                        <noindex>
                            <a href="https://forum.bits.media" target="_blank">
                                <div className="item">
                                    <div className="item-img"><img src="/res/dotsatoshi/img/bits.png" alt="" /></div>
                                    <p>Popularne forum Bitcoin. Kryptowaluty, fintech, blockchain, mining, DeFi news. Recenzje górników, bitcoinów, forków i kursów altcoinów.</p>
                                </div>
                            </a>
                          
                            <a href="https://www.mywot.com" target="_blank">
                                <div className="item">
                                    <div className="item-img"><img src="/res/dotsatoshi/img/wot_logo.svg" alt="" /></div>
                                    <p>Usługa, która poprzez rozszerzenie filtruje ruch w przeglądarce i posiada niezależną ocenę jakości i bezpieczeństwa stron internetowych przed zagrożeniami takimi jak oszustwa, złośliwe oprogramowanie, phishing i kradzież tożsamości.</p>
                                </div>
                            </a>
                        </noindex>
                    </div>
                    <div className="guarantee-wrapper-right">
                        <p /><p>Drodzy Użytkownicy!</p>
                        <p>Serwis internetowy BTC-EXCHANGE24 świadczy profesjonalne usługi w zakresie konwersji walut cyfrowych, elektronicznych i fiat. Działamy bez przerw i weekendów. Średni czas przetwarzania nie przekracza 5 minut. Specjaliści wsparcia technicznego są zawsze gotowi zaoferować swoją pomoc w rozwiązywaniu problemów z naszym serwisem.&nbsp;</p>
                        <p>Niezawodność, bezpieczeństwo i szybkość to główne kryteria BTC-EXCHANGE24! Dzięki tym cechom nasz serwis cieszy się dużą renomą na głównych forach branżowych i monitorach. Indywidualne podejście do każdego użytkownika oraz wygodny system rabatów i kursów to dokładnie to, czego potrzebują obecnie użytkownicy kantorów internetowych.</p><p />
                        <div className="guarantee-wrapper-links">
                            <a href="/rules/">Zasady wymiany</a>
                            <a href="/aml/">AML</a>
                        </div>
                    </div>
                </div>
                <noindex>
                    <div className="guarantee-wrapper-bottom">

                        <a href="https://www.master-x.com" target="_blank" className="item">
                            <img src="/res/dotsatoshi/img/masterX.png" alt="MasterX" />
                            <span>MasterX</span>
                        </a>
                        <a href="https://bitcointalk.org" target="_blank" className="item">
                            <img src="/res/dotsatoshi/img/bitcointalk.png" alt="BitcoinTalk" />
                            <span>BitcoinTalk</span>
                        </a>
                        <a href="https://forum.bits.media" target="_blank" className="item">
                            <img src="/res/dotsatoshi/img/BitsMedia.png" alt="BitsMedia" />
                            <span>BitsMedia</span>
                        </a>
                        <a href="https://mmgp.com" target="_blank" className="item">
                            <img src="/res/dotsatoshi/img/mmgp.png" alt="MMGP" />
                            <span>MMGP</span>
                        </a>
                        <a href="https://antimmgp.ru" target="_blank" className="item">
                            <span>AntiMMGP</span>
                        </a>
                        <a href="https://cryptotalk.org/" target="_blank" className="item">
                            <img src="/res/dotsatoshi/img/cryptotalk.png" alt="cryptotalk" />
                            <span>CryptoTalk</span>
                        </a>
                    </div>
                </noindex></div></div>
        
    </div>
    )
}
export default Warranties