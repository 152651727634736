import { makeAutoObservable } from "mobx";

class Store {
    variables = { signInOpened: false, signUpOpened: false,forgotOpened:false };

    constructor() {
        makeAutoObservable(this);
    }

    signInChange = (state) => {
        this.variables.signInOpened = state;
        console.log(this.variables);
    };
    signUpChange = (state) => {
        this.variables.signUpOpened = state;
        console.log(this.variables);
    };
    forgotChange = (state) => {
        this.variables.forgotOpened = state;
        console.log(this.variables);
    };
}
export default new Store();
