import React, { useEffect } from "react";
import { listMyOrders } from "../../Redux/Actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import uniqid from "uniqid";
import Moment from "react-moment";
import { Link } from "react-router-dom/cjs/react-router-dom";
const Orders = () => {
    const dispatch = useDispatch();
    const userOrders = useSelector((state) => state.orderListMy);
    const { loading, orders } = userOrders;
    useEffect(() => {
        dispatch(listMyOrders());
    }, [dispatch]);
    console.log(orders);
    return (
        <div className="content">
  <div className="content">
    <div className="container">
      <div className="content-top cabinet-top">
        <h1 className="title">Szafka osobista</h1>
        <div className="cabinet-top-right">
          <div className="item">
          Twój rabat          <span>0%</span>
          </div>
          <div className="item">
          Saldo poleceń         <span>0$</span>
          </div>
        </div>
      </div>
      <div className="content-wrapper cabinet-content bg-opacity">
        <div className="cabinet-content-wrapper">
          <div className="cabinet-content-left">
            <div className="cabinet-history-top">
              <h2>Historia transakcji</h2>
            </div>
            <div className="cabinet-history-items">
		{!loading?
		orders?
		orders.map((item)=>{
			return <div className="item">
			<div className="item-left">
			  <div className="item-to">
			    <div className="item-img"><img src={item.imageSend} className="miniIcon" alt={item.titleSend} /></div>
			    <div className="item-info">
			      <strong>{item.sendCount} {item.symbolSend}</strong> <span>{item.titleSend}</span>
			    </div>
			  </div>
			  <div className="item-arrow" />
			  <div className="item-from">
			    <div className="item-img"><img src={item.imageReceive} className="miniIcon" alt={item.titleReceive} /></div>
			    <div className="item-info">
			      <strong>{item.sendCount * item.exchangeRate} {item.symbolReceive}</strong> <span>{item.titleReceive}</span>
			    </div>
			  </div>
			</div>
			<div className="item-right">
			  <div className="order-info">
			    <strong>#{item._id}</strong>
			    <span>{item.createdAt}</span>
			    <span>
			      1 {item.symbolSend} -
			      {item.exchangeRate} {item.symbolReceive}          </span>
			  </div>
			  <div className="item-last">
			    <span className="status">{item.isPaid
                                        ? item.isError
                                            ? <span style={{color:"red",fontSize:"1.25rem"}}>Pomyłka!</span>
                                            : "Zrobione!"
                                        : "Zostało przyjęte do weryfikacji!"}</span>
			    <Link className="btn btn-white popup-modal orderDetail" to={`/order/${item._id}`}>Czytaj więcej</Link>
			  </div>
			</div>
		      </div>
		      
		}):<div className="ordersEmpty text-center mt-4">
                <h5>Nie miałeś jeszcze żadnych wymian</h5>
                <a href="/" className="btn btn-primary mt-3">Rozpoczęcie wymiany</a>
              </div>:null}
              
            </div>
          </div>
          <div className="cabinet-content-right">
            <div className="cabinet-menu">
              <a href="/account/orders/" className="active">Historia transakcji</a>
              <a href="/account/referals/" className>Program skierowań</a>
              <a href="/account/api/" className>API</a>
              <a href="/account/settings/" className>Ustawienia</a>
              <a href="/account/verification/" className>Weryfikacja</a>
              <a href="/account/balance/" className>Równowaga</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    );
};

export default Orders;
