import React from "react";

const Reviews=()=>{
    return(<div className="content">
    <div className="container">
      <div className="content-top ">
        <h1 className="title">Świadectwa dla nas</h1>
      </div>
      <div className="reviewsWrp">
        <div className="reviewsList">
          <div className="reviewsItem">
            <div className="top-line">
              <div className="name">Bolesław</div>
              <div className="revRait rait-5-stars">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
              </div>
            </div>
            <div className="revText">Wszystko dotarło, bardzo zadowoleni, szybko i sprawnie reagują, polecam</div>
          </div>
          <div className="reviewsItem">
            <div className="top-line">
              <div className="name">Bronisław</div>
              <div className="revRait rait-5-stars">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
              </div>
            </div>
            <div className="revText">Wymiana zakończyła się sukcesem. Szybciej niż myślałem. Dziękuję za szybką reakcję. Polecam.</div>
          </div>
          <div className="reviewsItem">
            <div className="top-line">
              <div className="name">Kazimierz</div>
              <div className="revRait rait-5-stars">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
              </div>
            </div>
            <div className="revText">Jakość usług, zmień to, nie będziesz żałował</div>
          </div>
          <div className="reviewsItem">
            <div className="top-line">
              <div className="name">Lesław</div>
              <div className="revRait rait-5-stars">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
              </div>
            </div>
            <div className="revText">Sprzedałem kryptowaluty za dolary bez żadnych problemów</div>
          </div>
          <div className="reviewsItem">
            <div className="top-line">
              <div className="name">Marat</div>
              <div className="revRait rait-0-stars">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
              </div>
            </div>
            <div className="revText">Dobry wymiennik.Wypełniony wniosek szybko, strona działa bez usterek, wszystko co miało przyjść - dostałem.</div>
          </div>
          <div className="reviewsItem">
            <div className="top-line">
              <div className="name">Przemysław</div>
              <div className="revRait rait-0-stars">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
                </svg>
              </div>
            </div>
            <div className="revText">Kupił bitcoiny po najlepszej cenie</div>
          </div>
        </div>
        <div className="pagination">
        </div>
      </div></div>      
      </div>
  )
}
export default Reviews;