import React, { useEffect } from "react";
import { clientSideLink } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../Redux/Actions/userActions";
import CopyToClipboard from "react-copy-to-clipboard";
const Referals = () => {
  const dispatch=useDispatch();
  console.log(localStorage.getItem("userInfo"));

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user } = userDetails;
  
console.log(user);
  useEffect(()=>{
    dispatch(getUserDetails());
  },[dispatch]);
  console.log(loading,user);
    return (
        <div className="content">
            <div className="content">
                <div className="container">
                    <div className="content-top cabinet-top">
                        <h1 className="title">Szafka osobista</h1>
                        <div className="cabinet-top-right">
                            <div className="item">
                            Twój rabat <span>0%</span>
                            </div>
                            <div className="item">
                            Saldo poleceń <span>0$</span>
                            </div>
                        </div>
                    </div>
                    <div className="content-wrapper cabinet-content bg-opacity">
                        <div className="cabinet-content-wrapper">
                            <div className="cabinet-content-left">
                                <div className="cabinet-referral">
                                    <h2>Program skierowań</h2>
                                    <div className="cabinet-referral-input">
                                        <label>
                                        Twój link polecający, umieść go
                                            na swoich stronach internetowych, portalach społecznościowych i
                                            forach
                                        </label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                defaultValue={`${clientSideLink}/?rid=${
                                                    JSON.parse(
                                                        localStorage.getItem(
                                                            "userInfo"
                                                        )
                                                    )._id
                                                }`}
                                            />
                                           <CopyToClipboard text={`${clientSideLink}/?rid=${JSON.parse(
                                                        localStorage.getItem(
                                                            "userInfo"
                                                        )
                                                    )._id}`}>
                                           <span
                                                className="copy-clipboard"
                                                
                                            />
                                           </CopyToClipboard>
                                          
                                        </div>
                                    </div>
                                    <div className="cabinet-referral-center">
                                        <div className="cabinet-referral-stats">
                                            <h3>Statystyki</h3>
                                            <div className="items">
                                                <div className="item">
                                                    <strong>{!loading? user.referals.entries:null}</strong>
                                                    <span>Przejścia</span>
                                                </div>
                                                <div className="item">
                                                    <strong>{!loading? user.referals.registered:null}</strong>
                                                    <span>
                                                    Zarejestrowany
                                                    </span>
                                                </div>
                                                
                                                <div className="item">
                                                    <strong>{!loading? user.referals.efficiency:null}</strong>
                                                    <span>Wydajność</span>
                                                </div>
                                                <div className="item">
                                                    <strong>{!loading? user.referals.total:null}</strong>
                                                    <span>Łączna liczba wymian</span>
                                                </div>
                                                <div className="item">
                                                    <strong>{!loading? user.referals.income.toFixed(2):null}</strong>
                                                    <span>
                                                    Całkowity dochód (USD)
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cabinet-referral-items">
                                            <h3>Polecenia</h3>
                                            Pusta lista{" "}
                                        </div>
                                    </div>
                                    <div className="cabinet-referral-bottom">
                                        <h3>Wniosek o wycofanie</h3>
                                        <p>
                                        Aby obciążyć środki, wypełnij
                                            poniższy formularz, a nasz menedżer skontaktuje się z Tobą.
                                            Nasz menedżer skontaktuje się z Tobą w celu wyjaśnienia szczegółów.
                                        </p>
                                        <div id="refout-form">
                                            <div className="form-group">
                                                <label>System płatności</label>
                                                <div className="dd-list">
                                                    <select
                                                        className="form-control f-input"
                                                        id="refout-psid"
                                                        name="psid"
                                                    >
                                                        <option value={0}>
                                                            - Wybierz -
                                                        </option>
                                                        <option value={171}>
                                                            USDT ERC20
                                                        </option>
                                                        <option value={169}>
                                                            USDT TRC20
                                                        </option>
                                                        
                                                        <option
                                                            value={28}
                                                            disabled
                                                        >
                                                            Advcash
                                                            USD&nbsp;(Minimum
                                                            kwota: 500 USD)
                                                        </option>
                                                        <option
                                                            value={48}
                                                            disabled
                                                        >
                                                            Advcash
                                                            EUR&nbsp;(Minimum
                                                            kwota: 500 EUR)
                                                        </option>
                                                        <option
                                                            value={57}
                                                            disabled
                                                        >
                                                            Advcash
                                                            PLN&nbsp;(Minimum
                                                            kwota: 3000 PLN)
                                                        </option>
                                                        <option
                                                            value={26}
                                                            disabled
                                                        >
                                                            Visa/MasterCard
                                                            PLN&nbsp;(Minimum
                                                            kwota: 2000 PLN)
                                                        </option>
                                                        
                                                    </select>
                                                </div>
                                            </div>
                                            <div id="refout-props" />
                                        </div>{" "}
                                        <br style={{ clear: "both" }} />
                                    </div>
                                    <div id="refoutsHistory">Pusta lista</div>
                                </div>{" "}
                            </div>
                            <div className="cabinet-content-right">
                                <div className="cabinet-menu">
                                    <a href="/account/orders/" className>
                                    Historia transakcji
                                    </a>
                                    <a
                                        href="/account/referals/"
                                        className="active"
                                    >
                                        Program skierowań
                                    </a>
                                    <a href="/account/api/" className>
                                        API
                                    </a>
                                    <a href="/account/settings/" className>
                                    Ustawienia
                                    </a>
                                    <a href="/account/verification/" className>
                                    Weryfikacja
                                    </a>
                                    <a href="/account/balance/" className>
                                    Równowaga
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Referals;
