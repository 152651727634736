import React from "react";
import { telegramSupportLink } from "../App";

const Footer = () => {
	return (
		<footer>
  <div className="container">
    <div className="footer-main">
      <div className="footer-logo">
        <a href="/"><img src="/res/dotsatoshi/img/logo.svg" alt="logo" /></a>
      </div>
      <p className="copyright">Copyright © 2022 DOTSATOSHI. </p>
    </div>
    <div className="footerMenu">
      <div className="footerMenuBlock">
        <a href="/aml/">AML</a>
        <a href="/faq/">Częste pytania</a>
        <a href="/rules/">Zasady obsługi</a>
      </div>
      <div className="footerMenuBlock">
        <a href="/warranties/">Gwarancje niezawodności</a>
        
        <a href="/privacy-policy/">Polityka prywatności</a>
      </div>
      <div className="footerMenuBlock">
        <a href="/reviews/">Recenzje</a>
        <a href={telegramSupportLink} target="_blank" rel="noreferrer">Jesteśmy na Telegramie</a>
        
      </div>
    </div>
  </div>
</footer>


	);
};

export default Footer;
