import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../Redux/Actions/userActions";
import Toast from "../LoadingError/toast";
import { toast } from "react-toastify";
import mobxStore from "../../mobx/mobxStore";
const Register = ({ location, history }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
        const [passwordError,setPasswordError]=useState(false);
        const { variables, signInChange,signUpChange } = mobxStore;
    const Toastobjects = {
        pauseOnFocusLoss: false,
        draggable: false,
        pauseOnHover: false,
        autoClose: 2000,
    };
    const toastId = React.useRef(null);
    const dispatch = useDispatch();
    const redirect = location.search ? location.search.split("=")[1] : "/";

    const userRegister = useSelector((state) => state.userRegister);
    const { error, loading, userInfo } = userRegister;
    console.log(error);
    useEffect(() => {
        if (userInfo) {
                window.location.href=redirect;
        }
        else if(error){
                if (!toast.isActive(toastId.current)) {
			toastId.current = toast.error(
				error,
			    Toastobjects
			);
		    } 
        }
        console.log("use effect");
    }, [userRegister]);
    const submitHandler = (e) => {
        e.preventDefault();
        if(password==passwordRepeat && validateEmail(email)){

                dispatch(register(email, password));
        }
        else if(password!=passwordRepeat){
                setPasswordError(true);
                if (!toast.isActive(toastId.current)) {
			toastId.current = toast.error(
				"Hasło nie pasuje!",
			    Toastobjects
			);
		    }
        }
        else if(!validateEmail(email)){
                if (!toast.isActive(toastId.current)) {
			toastId.current = toast.error(
				"Wprowadź prawidłowy adres e-mail",
			    Toastobjects
			);
		    }
        }
    };
    return (
        <React.Fragment>
                <Toast/>
            <div className="mfp-bg mfp-ready" />
            <div
                className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                tabIndex={-1}
                style={{ overflow: "hidden auto" }}
            >
                <div className="mfp-container mfp-inline-holder">
                    <div className="mfp-content">
                        <div
                            className="modal register-modal"
                            id="register-modal"
                        >
                            <div className="modal-wrapper" id="register">
                                <h2 className="modal-title">Rejestracja</h2>
                                <div id="register-form">
                                    <div className="input-group form-group">
                                        <input
                                            className="form-control f-input"
                                            placeholder="Twój adres e-mail"
                                            type="email"
                                            name="rmail"
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            value={email}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="input-group form-group">
                                        <input
                                            className="form-control f-input"
                                            name="rpwd"
                                            placeholder="Wprowadź hasło"
                                            type="password"
                                            style={{border:`${passwordError?"1px solid red":""}`}}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            value={password}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="input-group form-group">
                                        <input
                                            className="form-control f-input"
                                            placeholder="Ponownie wprowadź hasło"
                                            type="password"
                                            style={{border:`${passwordError?"1px solid red":""}`}}
                                            name="rpwd2"
                                            onChange={(e) =>
                                                setPasswordRepeat(
                                                    e.target.value
                                                )
                                            }
                                            value={passwordRepeat}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="form-check">
                                        <label className="label-check">
                                            <input
                                                className="form-check-input f-input"
                                                name="rcheck"
                                                id="rcheck_reg"
                                                type="checkbox"
                                                defaultChecked
                                            />
                                            <span />
                                            Klikam przycisk rejestracji,
                                            zgadzam się na{" "}
                                            <a href="/rules/" target="_blank">
                                            zasady wymiany{" "}
                                            </a>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <label className="label-check">
                                            <input
                                                className="form-check-input f-input"
                                                name="subscription"
                                                id="subs_reg"
                                                type="checkbox"
                                                defaultChecked
                                            />
                                            <span />
                                            Otrzymuj powiadomienia o promocjach i
                                            zniżkach{" "}
                                        </label>
                                    </div>
                                   
                                    <div className="form-bottom">
                                        <button
                                            href="#"
                                            id="register-btn"
                                            className="btn"
                                            onClick={(e)=>submitHandler(e)}
                                        >
                                            Rejestracja
                                        </button>
                                        <button
                                            href="#login-modal"
                                            className="btn btn-white popup-modal"
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                signInChange(true);
                                                signUpChange(false);
                                            }}
                                        >
                                            Logowanie
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button
                                title="Close (Esc)"
                                type="button"
                                className="mfp-close"
                                onClick={() => signUpChange(false)}
                            >
                                ×
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Register;


const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };