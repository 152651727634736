import React from "react";
import { useState } from "react";
import { serverLink, telegramSupportLink } from "../App";
import axios from "axios";
import Toast from "../components/LoadingError/toast";
import { toast } from "react-toastify";
const Contacts = () => {
    const Toastobjects = {
        pauseOnFocusLoss: false,
        draggable: false,
        pauseOnHover: false,
        autoClose: 2000,
    };
    const toastId = React.useRef(null);
    const [userMail, setMail] = useState("");
    const [userName, setName] = useState("");
    const [userMessage, setMessage] = useState("");
    const submitHandler = async (e) => {
        console.log("submit");
        e.preventDefault();
        const feedback = await axios
            .post(serverLink + "/api/users/feedback", {
                email: userMail,
                name: userName,
                message: userMessage,
            })

            .catch(function (error) {
                console.log(error);
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error("Error. The the data you have entered", Toastobjects);
                }
                return { status: "error" };
            });
        console.log(feedback);
        if (feedback.status == 200) {
            setMessage("");
            setName("");
            setMail("");

            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(
                    "Thanks! We will contact you soon",
                    Toastobjects
                );
            }
        }
    };

    return (
        <div className="content">
            <Toast />
            <div className="container">
                <div className="content-top ">
                    <h1 className="title">Kontakt</h1>
                    <p>
                        Jesteśmy zawsze dostępni, aby odpowiedzieć na wszelkie
                        pytania dotyczące naszej usługi i zapewnić wsparcie
                    </p>
                </div>
                <div className="contacts-wrapper">
                    <div className="contacts-wrapper-left bg-opacity">
                        <h3 className="contacts-title">
                            <img
                                src="/res/dotsatoshi/img/contect-telegram.svg"
                                alt=""
                            />{" "}
                            <a
                                href={telegramSupportLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Telegram
                            </a>
                        </h3>

                        <h3 className="contacts-title">
                            <img
                                src="/res/dotsatoshi/img/contact-location.svg"
                                alt=""
                            />{" "}
                            <span>Informacje o firmie</span>
                        </h3>
                        <div className="contacts-address">
                            <div>
                                Nazwa firmy: <b>Digital Exchange LLC</b>
                            </div>
                            <div>
                                Forma organizacyjna:{" "}
                                <b>Limited Liability Company</b>
                            </div>
                            <div>
                                Numer identyfikacyjny: <b>412759457</b>
                            </div>
                            <div>
                                Data rejestracji: <b>21/05/2021</b>
                            </div>
                            <div>
                                Organ rejestrujący:{" "}
                                <b>LEPL National Agency of Public Registry</b>
                            </div>
                            <div>
                                Adres prawny:{" "}
                                <b>Jana Kochanowskiego 20, 01-864 Warszawa</b>
                            </div>
                            <div>
                                Licencja:{" "}
                                <b>Free Industrial Zone Licence No.0110/462</b>
                            </div>
                            <div>
                                Kraj: <b>Poland</b>
                            </div>
                            <div>
                                Rodzaj działalności:{" "}
                                <b>Financial Service and Crypto Exchange</b>
                            </div>
                        </div>
                    </div>
                    <div className="contacts-wrapper-right bg-opacity">
                        <h2>Informacja zwrotna</h2>
                        <div id="contactForm">
                            <div className="input-group">
                                <label>Twój e-mail</label>
                                <input
                                    className="form-control f-input"
                                    type="email"
                                    id="d4"
                                    value={userMail}
                                    onChange={(e) => setMail(e.target.value)}
                                    name="email"
                                    placeholder="Email"
                                />
                            </div>
                            <div className="input-group">
                                <label>Twoje imię i nazwisko</label>
                                <input
                                    className="form-control f-input"
                                    id="d1"
                                    name="name"
                                    placeholder="Twoje imię i nazwisko"
                                    value={userName}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label>Twoja wiadomość</label>
                                <textarea
                                    value={userMessage}
                                    onChange={(e) => setMessage(e.target.value)}
                                    className="form-control f-input"
                                    id="d5"
                                    name="question"
                                    cols={30}
                                    placeholder="Tekst wiadomości"
                                    rows={10}
                                    defaultValue={""}
                                />
                            </div>

                            <div className="form-bottom">
                                <button
                                    className="btn"
                                    id="contactsFormSend"
                                    onClick={(e) => submitHandler(e)}
                                >
                                    Wyślij
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="notification-container" style={{ display: "none" }}>
                <div className="notification-header">
                    <h3>Powiadomienia</h3>
                    <span
                        className="notification-close"
                        onclick="document.querySelector('.notification-container').style.display = 'none';"
                    >
                        x
                    </span>
                </div>
                <div className="notification-list" />
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: "\n  .notification-container{\n    background-color: #eee;\n    position: fixed;\n    right: 0;\n    top: 0;\n    width: 100%;\n    max-width: 440px;\n    height: 100%;\n    z-index: 1000;\n    box-shadow: 0 0 10px rgba(0,0,0,.5);\n    display: flex;\n    flex-direction: column;\n  }\n  .notification-header{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px;\n  }\n  .notification-close{\n    font-size: 20px;\n    cursor: pointer;\n    padding: 5px;\n  }\n  .notification-element{\n    padding: 20px;\n    background-color: #fff;\n    margin: 10px;\n  }\n",
                }}
            />
        </div>
    );
};
export default Contacts;
