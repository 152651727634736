import React from "react";

const FAQ=()=>{
    return(<div className="content">
    <div className="container">
      <div className="content-top faq-top">
        <h1 className="title">Pytania i odpowiedzi</h1>
      </div>
      <div className="faq-mob-menu-wrapper">
        <div className="faq-mob-menu">
          <a href="#item-0">Czy muszę się zarejestrować na giełdzie?</a>
          <a href="#item-1">Procedura AML/KYC</a>
          <a href="#item-2">Kod kuponu</a>
          <a href="#item-3">Moja transakcja nie przeszła kontroli AML</a>
          <a href="#item-4">Jak poprawić wniosek z błędnymi danymi?</a>
          <a href="#item-5">Brak kodu na poczcie</a>
          <a href="#item-6">Jak zmienić adres e-mail powiązany z moim kontem?</a>
          <a href="#item-7">Jakie metody płatności są dostępne przy zakupie kryptowalut?</a>
          <a href="#item-8">Co to są adresy znaczników?</a>
          <a href="#item-9">Co to jest potwierdzenie transakcji?</a>
          <a href="#item-10">Błędna kwota przelewu</a>
          <a href="#item-11">Przekazanie waluty w niewłaściwej sieci</a>
          <a href="#item-12">Czy można anulować zakończoną transakcję?</a>
        </div>
      </div>
      <div className="content-wrapper faq-wrapper bg-opacity">
        <div className="faq-content-wrapper">
          <div className="faq-content-text">
            <div className="item faqElem" id="item-0" >
              <h2>Czy muszę się zarejestrować na giełdzie?</h2>
              <p>Do przeprowadzenia transakcji wymiany walut cyfrowych w serwisie internetowym DotSatoshi nie jest wymagana rejestracja.</p>
              <p>&nbsp;</p>        </div>
            <div className="item faqElem" id="item-1" data-url="/faq/procedura-amlkyc.html">
              <h2>Procedura AML/KYC</h2>
              <p>Weryfikacja AML jest środkiem bezpieczeństwa, który potwierdza, że to posiadacz karty/rachunku dokonuje przelewu środków.</p>
              <p><a href="/aml/">AML</a>  jest wewnętrzną regulacją, która określa zasady zwalczania prania pieniędzy, finansowania terroryzmu i innych nielegalnych działań. Operacja ta obejmuje identyfikację Użytkownika, jego transakcji oraz połączenie z systemami płatności. W społeczności globalnej procedura ta jest wymagana przez bazowe instytucje finansowe.</p>        </div>
            <div className="item faqElem" id="item-2" data-url="/faq/kod-kupona.html">
              <h2>Kod kuponu</h2>
              <p>Kod kuponu to specjalny alfanumeryczny zestaw znaków, który uprawnia do dodatkowego rabatu, który jest dodawany do rabatu, który już posiadasz na swoim koncie.</p>
              <p>Kupon można otrzymać po udanej wymianie, lub na forach/stronach Partnerów.</p>
              <p>Szczegółowy opis kuponu dostępny jest w Strefie Osobistej Użytkownika, a po więcej informacji można skontaktować się z   <a href="/contacts/">zespołem wsparcia</a> serwisu online.</p>        </div>
            <div className="item faqElem" id="item-3" data-url="/faq/moja-tranzakcija-ne-proshla-aml-proverku.html">
              <h2>Moja transakcja nie przeszła kontroli AML</h2>
              <p>Korzystanie z usługi DotSatoshi oznacza konieczność sprawdzenia pod kątem AML wszystkich adresów portfeli wskazanych we wniosku Użytkownika. Więcej na ten temat można przeczytać w  <a href="/rules/">Regulaminie</a>.</p>
              <p>W przypadku, gdy Twoja transakcja nie przeszła kontroli AML, będziesz musiał skontaktować się z naszym zespołem wsparcia w celu pełnej weryfikacji. Dopiero po przejściu tej procedury środki zostaną zwrócone na adres nadawcy (po potrąceniu opłaty za zwrot).</p>        </div>
            <div className="item faqElem" id="item-4" data-url="/faq/kak-ispravit-zajavku-s-nepravil-nymi-rekvizitami.html">
              <h2>Jak poprawić wniosek z błędnymi danymi?</h2>
              <p>Może się zdarzyć, że po utworzeniu wniosku zorientujesz się, że wprowadziłeś złe dane. W takiej sytuacji należy jak najszybciej poinformować <a href="/contacts/">support</a>, aby zapobiec płatności na niewłaściwe dane. Usługa DotSatoshi nie zajmuje średnio więcej niż 5 minut na przetworzenie Twojego żądania.</p>
              <p>Jeśli Twój wniosek został już rozpatrzony i środki zostały wysłane, nie możesz odzyskać swoich środków.</p>
              <p>Jeśli Twoja aplikacja została zawieszona, skontaktuj się z zespołem wsparcia, aby rozwiązać sytuację i poprawić dane osobowe.</p>        </div>
            <div className="item faqElem" id="item-5" data-url="/faq/ne-prishel-kod-na-pochtu.html">
              <h2>Brak kodu na poczcie</h2>
              <p>Po zakończeniu procesu rejestracji na adres e-mail podany podczas rejestracji zostanie wysłany kod potwierdzający. Jeśli ten e-mail zostanie zagubiony lub nie zostanie dostarczony, postępuj zgodnie z podanymi instrukcjami:</p>
              <p>1. Przede wszystkim sprawdź swój folder ze spamem. Na tym zazwyczaj kończy się większość takich maili.</p>
              <p>2. Jeśli nadal nie możesz znaleźć wiadomości e-mail z kodem, przejdź na stronę Przywróć hasło i wprowadź adres e-mail, którego użyłeś do rejestracji konta. Następnie na Twój adres zostanie ponownie wysłana wiadomość z potwierdzeniem.</p>
              <p>Jeśli po wykonaniu tych czynności nadal nie otrzymasz potwierdzenia, skontaktuj się z <a href="/contacts/">pomocą techniczną DotSatoshi</a>, aby rozwiązać problem.</p>        </div>  
            <div className="item faqElem" id="item-6" data-url="/faq/kak-izmenit-e-mail-privjazannyj-k-moemu-akkauntu.html">
              <h2>Jak zmienić adres e-mail powiązany z moim kontem?</h2>
              <p>Aby zmienić skrzynkę pocztową konta e-mail, należy skontaktować się z <a href="/contacts/">obsługą techniczną</a> giełdy internetowej. Zmiana skrzynki pocztowej konta Użytkownika jest możliwa tylko poprzez napisanie wniosku o zmianę z aktualnej skrzynki.</p>        </div>
            <div className="item faqElem" id="item-7" data-url="/faq/kakie-metody-oplaty-dostupny-dlja-pokupki-kriptovaljuty.html">
              <h2>Jakie metody płatności są dostępne przy zakupie kryptowalut?</h2>
              <p>W przypadku zakupów w kryptowalutach dostępne będą następujące metody płatności: popularne karty bankowe i karty VISA, konta rublowe, euro i dolarowe popularnych systemów płatniczych oraz inne kryptowaluty.</p>        </div>
            <div className="item faqElem" id="item-8" data-url="/faq/chto-takoe-tag-adresa.html">
              <h2>Co to są adresy znaczników?</h2>
              <p>Znacznik Destination jest opcjonalnym parametrem adresowym oznaczonym numerycznie i/lub łańcuchowo. Identyfikuje odbiorcę przelewu za adresem konkretnej kryptowaluty na scentralizowanej giełdzie.</p>
              <p>Notatka tagowa wykorzystuje takie kryptowaluty jak: XRP (XRP), Stellar (XLM), Cosmos (ATOM).</p>        </div>
            <div className="item faqElem" id="item-9" data-url="/faq/chto-takoe-podtverzhdenie-tranzakcii.html">
              <h2>Co to jest potwierdzenie transakcji?</h2>
              <p>Aby przenieść kryptowalutę Bitcoin z jednego portfela do drugiego, konieczne jest uzyskanie potwierdzenia z sieci Bitcoin. Proces ten pełni funkcję zabezpieczającą, dopiero po nim odbiorca może dysponować przesłaną kryptowalutą. W ten sposób system blockchain zapobiega ponownemu użyciu tych samych jednostek cyfrowych.</p>
              <p>Czas potrzebny na potwierdzenie transakcji online może wynosić od 10 minut do 2 dni i zależy od wielu czynników takich jak: obciążenie sieci, wysokość prowizji, jakość połączenia internetowego itp.</p>        </div>
            <div className="item faqElem" id="item-10" data-url="/faq/oshibsja-s-summoj-perevoda.html">
              <h2>Błędna kwota przelewu</h2>
              <p>Może się zdarzyć, że po złożeniu wniosku zorientujesz się, że wpisałeś złe dane lub pomyliłeś się co do kwoty przelewu. W takim przypadku należy jak najszybciej poinformować <a href="/contacts/">serwis wsparcia</a> o zaistniałej sytuacji.</p>
              <p>Jeśli Twój wniosek został już rozpatrzony i opłacony, kwota otrzymana na podane przez Ciebie dane została przeliczona na podstawie otrzymanych od Ciebie środków.</p>        </div>
            <div className="item faqElem" id="item-11" data-url="/faq/perevel-valjutu-ne-po-toj-seti.html">
              <h2>Przekazanie waluty w niewłaściwej sieci</h2>
              <p>Zdarzają się sytuacje, w których Użytkownicy popełniają błąd przy wyborze sieci do transakcji. Jeśli podczas tworzenia zlecenia w polu "Wyślij" podałeś niewłaściwą sieć, Twoje zlecenie nie zostanie wykonane automatycznie. Na przykład stworzyłeś zlecenie wymiany USDT ERC20 na BTC, ale wysyłasz monety USDT do DotSatoshi w sieci BEP20. W takiej sytuacji nie należy panikować, należy skontaktować się z DotSatoshi <a href="/contacts">wsparcie techniczne</a>. Есть шанс, что вы сможете вернуть токены.</p>        </div>
            <div className="item faqElem" id="item-12" data-url="/faq/mozhno-li-otmenit-zavershennuju-sdelku.html">
              <h2>Czy można anulować zakończoną transakcję?</h2>
              <p>Nie. Zakończonej transakcji nie można cofnąć.</p>        </div>
          </div>
          <div className="faq-content-menu">
            <div className="faq-menu">
              <a href="#item-0">Czy muszę się zarejestrować na giełdzie?</a>
              <a href="#item-1">Procedura AML/KYC</a>
              <a href="#item-2">Kod kuponu</a>
              <a href="#item-3">Moja transakcja nie przeszła kontroli AML</a>
              <a href="#item-4" >Jak poprawić wniosek z błędnymi danymi?</a>
              <a href="#item-5">Brak kodu na poczcie</a>
              <a href="#item-6" >Jak zmienić adres e-mail powiązany z moim kontem?</a>
              <a href="#item-7" >Jakie metody płatności są dostępne przy zakupie kryptowalut?</a>
              <a href="#item-8" >Co to są adresy znaczników?</a>
              <a href="#item-9">Co to jest potwierdzenie transakcji?</a>
              <a href="#item-10" >Błędna kwota przelewu</a>
              <a href="#item-11">Przekazanie waluty w niewłaściwej sieci</a>
              <a href="#item-12">Czy można anulować zakończoną transakcję?</a>
            </div>
          </div>
        </div>
      </div>
    </div>      
    
  </div>
  )
}

export default FAQ;