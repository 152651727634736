import React from "react";

const AML=()=>{
 return( <div className="content">
 <div className="container">
   <div className="content-top">
     <h1 className="title">Polityka AML serwisu internetowego</h1>
   </div>
   <div className="content-wrapper bg-opacity">
     <h1>System AML/KYC (anti-money laundering/customer due diligence) oraz międzynarodowe procedury sankcji finansowych. Kontrole wewnętrzne w zakresie wdrażania ustawy AML</h1>
     <p>Ustawa AML zapewnia kompleksowy zestaw środków pomagających w radzeniu sobie z nadużyciami związanymi z praniem pieniędzy i ukrywaniem prawdziwych dochodów osoby lub podmiotu, a także z nielegalnym sponsorowaniem grup terrorystycznych. Ustawa szczegółowo określa, w jaki sposób zabezpiecza się transakcje finansowe, aby nie naruszać wymogów należytej staranności, a jednocześnie uwzględnia międzynarodowe sankcje ograniczające działalność przestępczą. Przepisy przewidują również środki służące do wykrywania transakcji, które mogą być uznane za podejrzane. Nietypowe transakcje i operacje finansowe przyciągają większą uwagę organów regulacyjnych i dlatego podlegają wzmożonej kontroli.</p>
     <p><strong>Zestawienie podstawowych pojęć i symboli</strong></p>
     <p>Firma AML/KYC musi prześwietlać swoich klientów pod kątem wykorzystywania nielegalnych funduszy i sposobów uzyskiwania dochodów, a także kierowania aktywów do wspierania nielegalnych podmiotów. Konkretnie chodzi o proceder prania pieniędzy, który polega na ukrywaniu prawdziwych działań, celów, metod uzyskiwania lub przekazywania mienia i praw do niego. Jako pranie pieniędzy określa się również dochody z oszustw i innych działań przestępczych. Zgodnie z ustawą AML, pranie pieniędzy przejawia się w takich działaniach</p>
     <ul>
       <li>Udzielanie wsparcia finansowego osobom zaangażowanym w działalność przestępczą;</li>
       <li>unikanie podatków, opłat państwowych i w ogóle odpowiedzialności prawnej za osiąganie zysków;</li>
       <li>Otrzymanie, przekazanie, zakup, posiadanie lub wykorzystanie do własnych celów środków finansowych lub mienia uzyskanego w wyniku nielegalnej działalności;</li>
       <li>zatajenie lub świadome wprowadzenie w błąd co do źródła zysków;</li>
       <li>nabywanie przedmiotów wartościowych i mienia ze środków uzyskanych w wyniku nielegalnej działalności na terytorium innych państw.</li>
     </ul>
     <p>Pranie pieniędzy jest również często związane z finansowaniem gangów przestępczych, aż do gangów terrorystycznych włącznie, które zagrażają życiu i zdrowiu wielu ludzi. Kodeks karny definiuje proces sponsorowania terrorystów jako bezprawny i ściga nie tylko tych, którzy są bezpośrednio zaangażowani w przekazywanie pieniędzy przestępcom lub osiąganie zysków w celu wspierania grup, ale także tych, którzy mają pewną wiedzę o przepływach finansowych wykorzystywanych do sponsorowania terrorystów. Ustawa AML umożliwia identyfikację niegodnych zaufania klientów, którzy byli zaangażowani w wątpliwe transakcje lub działania z udziałem pieniędzy, ukaranie klienta lub reprezentowanej przez niego firmy, a także ściganie ich w razie potrzeby i przewidzianego prawem postępowania.</p>
     <p>Sankcje to swoiste środki oddziaływania, które pozwalają na tłumienie niepożądanych działań, zapobieganie konfliktom, zwiększanie bezpieczeństwa, a także umacnianie podstaw demokracji, podtrzymywanie władzy i prawa oraz ochronę praw człowieka. Jeśli sankcje są nakładane przez organizacje międzynarodowe - takie jak UE, ONZ lub rząd państwa na szczeblu międzynarodowym - nazywane są sankcjami międzynarodowymi.</p>
     <p>W tym poradniku zostaną również wspomniane następujące pojęcia:</p>
     <ol>
       <li>PEP to angielski akronim oznaczający politically exposed person. Technicznie rzecz biorąc, jest to osoba fizyczna, która obecnie zajmuje (lub zajmowała w przeszłości) stanowisko lidera lub członka organizacji publicznej, organu władzy, w tym rządu, parlamentu, ministerstwa, władzy ustawodawczej, sądowniczej lub wykonawczej, a także organizacji międzynarodowych, zarządu pojedynczej firmy itp.</li>
       <li>Lokalna osoba eksponowana politycznie to osoba, która zajmuje ważne stanowisko w organizacji danego państwa, a także samorządów, które zajmują się sprawami regionalnymi.</li>
       <li>Członkiem rodziny RER jest osoba fizyczna będąca małżonkiem lub odpowiednikiem RER oraz dziecko lub rodzic, tj. pozostająca z osobą RER w bezpośredniej relacji rodzinnej.</li>
       <li>Osobą bliską RER może być osoba fizyczna, która reprezentuje interesy RER, jest współwłaścicielem przedsiębiorstwa i pozostaje w bezpośrednich relacjach biznesowych z RER.</li>
     </ol>
     <p>&nbsp;W celu uniknięcia pomyłek w interpretacji AML, należy wyraźnie przestrzegać głównych przepisów i regulacji.</p>
     <p><strong>Najważniejsze przepisy ustawy</strong></p>
     <p>Zgodnie z ustawą AML, niniejszy przewodnik musi zostać przekazany wszystkim pracownikom firmy oraz jej klientom. Wytyczne są przeznaczone do użytku ściśle wewnętrznego i wykorzystują informacje pochodzące z oryginalnego tekstu ustawy AML i ustawy o sankcjach międzynarodowych.</p>
     <p>Wszyscy pracownicy firmy zobowiązują się do przestrzegania poniższych wymogów w celu zapobiegania praniu brudnych pieniędzy przez klientów, sponsorowaniu organizacji terrorystycznych i innym nielegalnym działaniom związanym z generowaniem nielegalnych dochodów. Ponadto pracownicy są zobowiązani do prywatnego zapoznania się z tekstem ustawy i jej ważnymi zmianami, a także z tekstem innych aktów prawnych mających bezpośredni związek z ograniczeniem i zapobieganiem praniu pieniędzy.</p>
     <p>Zarząd spółki zobowiązuje się do przekazania niniejszej instrukcji każdemu pracownikowi&nbsp;z obowiązkowym potwierdzeniem zapoznania się i wyrażeniem zgody przez pracowników. Tym samym wszyscy pracownicy zobowiązują się do przestrzegania instrukcji i ponoszą odpowiedzialność za spełnienie wymogów prawnych w pełnej zgodności z procedurami określonymi w ustawie AML.&nbsp;</p>
     <p><strong>Praca z klientami: procedury identyfikacji</strong></p>
     <p>Każdy pracownik firmy, który zna procedury działania, musi zgodnie z prawem stosować określone zasady postępowania z klientem. Muszą one obowiązywać za każdym razem, gdy pracownik i klient wchodzą w relacje biznesowe. Należy jednak zaznaczyć, że firma nie przewiduje relacji biznesowych pomiędzy pracownikami a w pełni uprawnionymi przedstawicielami klienta.</p>
     <p>Identyfikacja klienta jako osoby fizycznej odbywa się w następujący sposób</p>
     <ol>
       <li>Pierwszym warunkiem skutecznej identyfikacji jest dostarczenie dokumentu, który może potwierdzić tożsamość użytkownika systemu. W tym celu można posłużyć się paszportem, zezwoleniem na pobyt, prawem jazdy lub kopią któregokolwiek z tych dokumentów.</li>
       <li>Pracownik rejestruje i przechowuje dane każdego klienta - nazwisko, dane paszportowe, kod identyfikacyjny, datę urodzenia, miejsce zamieszkania i zameldowania, a także dane organizacji państwowej, która wydała dokument służący do identyfikacji klienta. Oprócz tych danych pracownik odnotowuje przynależność klienta do PEP lub do członków rodziny/partnerów PEP. Ważne jest również zachowanie danych kontaktowych do kontaktu z klientem - e-mail i numer telefonu komórkowego.</li>
       <li>Oprócz dokumentu, który może oficjalnie potwierdzić tożsamość klienta - paszportu, prawa jazdy, pozwolenia na pobyt itp. konieczne jest dostarczenie dokumentu potwierdzającego adres zamieszkania użytkownika systemu. Może to być rachunek za media lub rachunek bankowy, ważny maksymalnie przez 3 miesiące.</li>
     </ol>
     <p>Jeśli klient firmy pozycjonuje się jako osoba prawna, identyfikacja przebiega nieco inaczej. Po pierwsze, wymagany jest wypis z rejestru, wystawiony nie wcześniej niż 3 miesiące przed złożeniem wniosku do urzędnika prowadzącego firmę. Ważne jest, aby wyciąg był poświadczony notarialnie i w miarę możliwości apostille. Oficer firmy rejestruje następujące informacje dla każdego klienta-podmiotu prawnego:</p>
     <ul>
       <li>nazwa organizacji;</li>
       <li>datę rejestracji osoby prawnej i numer nadany w momencie rejestracji;</li>
       <li>Adres prawny organizacji i faktyczna lokalizacja biura;</li>
       <li>informacje o osobach, które mogą reprezentować osobę prawną oraz o właścicielach i udziałowcach organizacji.</li>
     </ul>
     <p>Obowiązkowo należy zebrać dane kontaktowe do kontaktu z klientem - numer telefonu i e-mail. Jeżeli w skład osoby prawnej wchodzą osoby fizyczne, konieczne jest zebranie osobno dla każdej osoby pakietu danych służących do identyfikacji osób fizycznych opisanych powyżej.</p>
     <p><strong>Procedury oceny ryzyka i określania środków kontroli</strong></p>
     <p>Nawiązując relacje biznesowe z jakimkolwiek klientem, firma jest zobowiązana na mocy ustawy AML do przeprowadzenia badania due diligence i oceny ryzyka - oceny prawdopodobieństwa sponsorowania organizacji terrorystycznych i prania pieniędzy przez konkretną osobę na podstawie przedstawionych informacji. Określany jest zestaw środków należytej staranności w celu weryfikacji wiarygodności klienta.</p>
     <p>Do oceny ryzyka wykorzystywane są trzy główne mierniki ryzyka - operacyjny, klienta i geograficzny. Są to parametry, które są przede wszystkim brane pod uwagę przy ocenie ryzyka. Wymiar geograficzny wyznacza zaangażowanie klienta w państwach i terytoriach, które wcześniej były objęte sankcjami międzynarodowymi, a także w krajach, w których brakuje odpowiedniego poziomu kontroli i zapobiegania współudziałowi finansowemu w terroryzmie i praniu pieniędzy. Klienci, którzy są lub mają powiązania z państwami o wysokim poziomie korupcji, lub o których wiadomo, że wspierają grupy terrorystyczne, są również narażeni na ryzyko geograficzne.</p>
     <p>Jeśli chodzi o ryzyko klienta, wskaźnik ten dotyczy przede wszystkim osób zajmujących eksponowane stanowiska polityczne, osób im bliskich oraz członków ich rodzin. Z tego powodu pracownicy firmy muszą na etapie rejestracji dowiedzieć się, czy klient należy do tej kategorii. Ponadto osoby objęte sankcjami międzynarodowymi mają być zgodnie z ustawą zaliczane do klientów wysokiego ryzyka. Jeszcze zanim klient wejdzie w relację biznesową z pracownikami firmy, mają oni możliwość zweryfikowania klienta za pomocą danych z list sankcyjnych.</p>
     <p>Wysokim ryzykiem dla klienta jest również osoba, której wcześniej udowodniono prowadzenie nielegalnej działalności, dokonywanie nielegalnych transakcji finansowych, pranie pieniędzy lub współudział w organizacjach terrorystycznych.</p>
     <p>Ostatni wskaźnik, ryzyko operacyjne, jest uznawany za wysoki i wymaga ścisłej kontroli klienta w przypadku, gdy</p>
     <ul>
       <li>płatność za transakcję jest przekazywana osobie postronnej, która nie występuje w rejestracji;</li>
       <li>klient jest proszony o ukrycie listy stron biorących udział w transakcji;</li>
       <li>cel transakcji nie jest jednoznaczny;</li>
       <li>mając do czynienia z aktywami cyfrowymi, zauważa się, że pochodzą one z różnych kont;</li>
       <li>kwoty wykorzystane do transakcji lub wpływające na konto klienta nie są typowe dla tego użytkownika (zwykle znacznie przekraczają standardowe wartości);</li>
       <li>każda transakcja finansowa jest przeprowadzana przy użyciu różnych kryptowalut.</li>
     </ul>
     <p>W każdej z opisanych sytuacji istnieje wysokie ryzyko, że klient bierze udział w praniu pieniędzy i może być powiązany z finansowaniem działalności przestępczej, dlatego firma ma jasno określone kategorie klientów, którzy nie mogą korzystać z usług firmy:</p>
     <ul>
       <li>klientów mieszkających w krajach wysokiego ryzyka lub mających z nimi powiązania;</li>
       <li>klientów na listach sankcji organizacji międzynarodowych;</li>
       <li>osoby zajmujące eksponowane stanowiska polityczne, ich przedstawiciele i członkowie rodzin;</li>
       <li>osoby, które były wcześniej ścigane za współudział finansowy w terroryzmie i pranie pieniędzy.</li>
     </ul>
     <p>Jeśli klient nie należy do żadnej z powyższych kategorii, ale jego działalność z takich czy innych powodów wydaje się firmie podejrzana, ma ona pełne prawo zezwolić na specjalne, wzmocnione badanie due diligence.&nbsp;<strong>Ryzyko związane z technologią informacyjną i sposoby jego kontroli</strong></p>
     <p>W zależności od stosowanej technologii informacyjnej, w tej części przyjrzymy się takim ryzykom informatycznym:</p>
     <ol>
       <li>Wyciek danych w wyniku zastosowania wadliwego oprogramowania lub błędu pracownika.</li>
       <li>Przekazywanie klientom fałszywych informacji - fałszowanie dokumentów tożsamości itp.</li>
       <li>Wykorzystanie złośliwego oprogramowania, zastępowanie oryginalnego oprogramowania hakerami z wbudowanym złośliwym oprogramowaniem oraz cyberataki.</li>
       <li>Problemy, które mogą pojawić się w działaniu systemu informatycznego</li>
     </ol>
     <p>Aby zapobiec wyciekom danych osobowych klientów i innych informacji, które są kluczowe dla prawidłowego funkcjonowania firmy, jej pracownicy mają obowiązek:</p>
     <ul>
       <li>pracują wyłącznie z wewnętrznymi serwerami organizacji prywatnych z ograniczoną odpowiedzialnością;</li>
       <li>Używaj tylko licencjonowanego oprogramowania zatwierdzonego przez Zarząd (ważne jest, aby regularnie aktualizować wersje oprogramowania i sprawdzać, czy nie ma awarii technicznych);</li>
       <li>Korzystaj wyłącznie ze sprzętu i oprogramowania firmy, nie używaj w swojej pracy własnego sprzętu.</li>
     </ul>
     <p>W celu uniknięcia niedokładnych informacji, w szczególności danych osobowych klientów, należy stosować specjalne środki potwierdzania danych. Alternatywnie można komunikować się z klientami za pomocą łącza wideo lub weryfikować dane, gdy pracownik i klient spotykają się osobiście. Jeśli istnieje podejrzenie, że klient podaje fałszywe informacje lub zataja ważne informacje, należy poprosić o udokumentowanie tych informacji.</p>
     <p>W celu zmniejszenia ryzyka włamania strony trzeciej poprzez cyberatak, wdrażane są następujące działania:</p>
     <ul>
       <li>regularne kontrole systemu w celu wykrycia podejrzanej aktywności w poszczególnych sektorach;</li>
       <li>Ciągłe testowanie usługi bezpieczeństwa systemu i opracowywanie nowych metod ochrony;</li>
       <li>stosowanie stale aktualizowanego oprogramowania do wykrywania wirusów i innego złośliwego oprogramowania.</li>
     </ul>
     <p>W celu zmniejszenia ryzyka związanego z systemem, do odzyskiwania informacji utraconych w wyniku awarii wykorzystywane są oddzielne serwery i systemy kopii zapasowych. Firma organizuje również infrastrukturę bezpieczeństwa dla systemu. Firma gwarantuje korzystanie z certyfikowanego systemu informatycznego (zgodnie z PCI/DSS).</p>
     <p>Dodatkowo okresowo, ale nie rzadziej niż raz w roku, przeprowadzane są specjalistyczne szkolenia z zakresu cyberbezpieczeństwa oraz audyty bezpieczeństwa systemów.&nbsp;</p>
     <p><strong>Środki należytej staranności</strong></p>
     <p>W kontaktach z klientami i angażując się w relacje biznesowe w firmie należy zwrócić szczególną uwagę na działania, informacje lub inne czynniki, które mogą bezpośrednio lub pośrednio wskazywać na zaangażowanie w pranie pieniędzy lub wykorzystanie środków finansowych do sponsorowania działalności przestępczej, w tym terrorystycznej. Często może na to wskazywać transakcja, która odbiega od normy, nie ma racjonalnego celu, a jej realizacja jest wystarczająco skomplikowana. Do weryfikacji takich transakcji i osób w nie zaangażowanych stosowane są środki należytej staranności:</p>
     <ul>
       <li>dokładna weryfikacja tożsamości strony transakcji, w tym wykorzystanie nie tylko dokumentów i ich kopii, ale także łączy wideo;</li>
       <li>domagając się szczegółowych informacji o działalności klienta, powiązaniach i celu podejrzanej transakcji;</li>
       <li>regularne sprawdzanie klienta: jego danych osobowych, dokonanych transakcji i innych informacji, które mogą potwierdzić czystość intencji klienta.</li>
     </ul>
     <p>Aktywnie stosowany jest również stały monitoring danych identyfikacyjnych, w ramach którego do klienta wysyłane są regularne prośby o aktualizację danych i dokumentów. Ponadto firma może przeprowadzić dochodzenie w celu ustalenia źródła pochodzenia środków wykorzystanych w danej transakcji.</p>
     <p>Due diligence zazwyczaj opiera się na udokumentowanym stanie faktycznym na podstawie oryginalnych dokumentów dostarczonych przez klienta. Kopie nie mogą stanowić w tym przypadku wystarczającego dowodu, z wyjątkiem kopii poświadczonych przez notariusza lub prawnika, jeżeli z jakiegoś powodu nie można przedstawić oryginałów dokumentów. Weryfikacja może również uwzględniać dane z rejestrów handlowych i instytucji kredytowych państw, które mają podobne wymagania dotyczące AML/KYC. Wszystkie te kroki muszą być podjęte jeszcze przed rozpoczęciem współpracy z klientem lub przed dokonaniem określonej transakcji.</p>
     <p>Jeśli ryzyko jest niewielkie, weryfikacja może być przeprowadzana rutynowo w czasie pobytu u klienta. W każdym przypadku wszystkie dokumenty, które muszą być podpisane przez klienta, muszą być podpisane przez klienta.&nbsp;</p>
     <p><strong>Rozszerzony due diligence i jego cechy szczególne</strong></p>
     <p>Due diligence w wersji rozszerzonej przeprowadzane jest w kilku sytuacjach.</p>
     <ol>
       <li>Jeśli informacje o kliencie są potwierdzone, ale dane nie pochodzą z określonego źródła, które nie odpowiada określonej fizycznej lokalizacji klienta.</li>
       <li>Jeżeli w trakcie identyfikacji, rejestracji lub weryfikacji personel firmy poweźmie podejrzenie co do prawdziwości podanych danych, w tym autentyczności dokumentacji.</li>
       <li>Jeśli nie jest możliwe potwierdzenie dokładnej tożsamości klienta w razie potrzeby.</li>
       <li>Jeśli sytuacja wiąże się z wysokim ryzykiem prania nielegalnych dochodów lub sponsorowania operacji/organizacji terrorystycznych.</li>
     </ol>
     <p>W każdej z powyższych sytuacji pracownik firmy ma prawo i obowiązek odwołać się i zastosować przynajmniej jeden ze wzmocnionych środków weryfikacji. Do takich środków należą.</p>
     <ol>
       <li>Potwierdzenie tożsamości klienta z dodatkowych wiarygodnych źródeł, np. od niezależnych organizacji, gdzie identyfikacja osoby fizycznej została przeprowadzona w jej obecności. Mogą to być instytucje kredytowe, rejestry publiczne i inne instytucje, które zawierają prawdziwe informacje o kliencie.</li>
       <li>Korzystanie z metody elektronicznego dostarczania dokumentów w celu ich uwierzytelnienia i poświadczenia przez prawnika lub notariusza.</li>
       <li>Załatwianie spraw z instytucją kredytową, w której otwarty jest rachunek na nazwisko klienta i dokonywanie wpłat na ten rachunek. Ważne jest, aby instytucja kredytowa podlegała podobnym wymogom AML/KYC.</li>
     </ol>
     <p>&nbsp;Za wszystkie te środki odpowiadają nasi pracownicy i w razie potrzeby mogą je zastosować wobec każdego z naszych klientów w przypadku problemów z identyfikacją i wątpliwości co do legalności przeprowadzanych transakcji.</p>
     <p><strong>Procedury identyfikacji osoby zajmującej eksponowane stanowisko polityczne</strong></p>
     <p>Podczas wypełniania formularza klient musi podać szereg danych, które są wymagane przez prawo i służą do dalszej identyfikacji klienta oraz przeprowadzenia weryfikacji informacji. W szczególności użytkownik systemu musi oświadczyć, że jest PEP lub członkiem rodziny PEP lub zaufaną osobą lub partnerem PEP. W przypadku, gdy klient jest krewnym lub przedstawicielem RER, musi on również podać dane tego RER. Podane informacje podlegają obowiązkowej weryfikacji, którą przeprowadzają pracownicy firmy korzystając z wiarygodnych źródeł publicznych.&nbsp;</p>
     <p><strong>Procedury identyfikacji osoby, która jest lub była wpisana na listę sankcji</strong></p>
     <p>Podczas nawiązywania relacji biznesowych klient musi wypełnić kwestionariusz, w którym musi podać wszystkie niezbędne informacje do przeprowadzenia odpowiedniej weryfikacji. Następnie funkcjonariusz sprawdzi, korzystając z oficjalnych źródeł, czy klient jest obecnie objęty sankcjami międzynarodowymi i czy rzeczywiście podał prawdziwe informacje, identyfikując się.&nbsp;</p>
     <p><strong>Szereg przesłanek wskazujących na pranie pieniędzy</strong></p>
     <p>W tej części wyszczególniono okoliczności i wskaźniki, które mogą wskazywać, bezpośrednio lub pośrednio, że klient jest zaangażowany w pranie pieniędzy i, co najmniej, powinny wzbudzić podejrzenia pracownika firmy. W oparciu o te przesłanki można rozpocząć bardziej dogłębne sprawdzanie przeszłości danej osoby.</p>
     <p>Opcja pierwsza to taka, że sama osoba nie wzbudza zaufania. Oznaki wskazujące na prawdopodobieństwo prania pieniędzy</p>
     <ul>
       <li>status, wygląd i zachowanie klienta nie odpowiadają treści transakcji, osoba zachowuje się podejrzanie;</li>
       <li>klient nie podpisuje dokumentów samodzielnie, powołując się na potrzebę pomocy z zewnątrz;</li>
       <li>klient był wcześniej podejrzany lub oskarżony o działalność przestępczą i oszustwa finansowe;</li>
       <li>klient nie jest w stanie jasno wyjaśnić charakteru transakcji i jej celu;</li>
       <li>klient żąda zbyt wysokiej stawki, która w tej sytuacji jest nieracjonalna;</li>
       <li>klient przeprowadza nieprawidłową transakcję z użyciem gotówki;</li>
       <li>Klient prowadzi transakcje w walucie cyfrowej (sporadycznie duże lub często małe) i działalność ta nie jest zgodna z linią biznesową klienta;</li>
       <li>klient przekazuje środki na inne nazwisko lub na swoje konta w innych krajach, co wiąże się z wysokim ryzykiem geograficznym;</li>
       <li>klient odmawia udzielenia informacji dotyczących celu transakcji;</li>
       <li>kwoty zaangażowane w transakcje są nienaturalnie wysokie i nie odpowiadają działalności klienta;</li>
       <li>klient nie może być zidentyfikowany lub odmawia podania informacji niezbędnych do identyfikacji;</li>
       <li>transakcja wygląda lub jest fikcyjna;</li>
       <li>oczekuje się, że w dłuższym okresie czasu rozliczenie będzie miało w przeważającej mierze charakter gotówkowy;</li>
       <li>klient zachowuje się podejrzanie, przypuszczalnie działa w cudzym interesie;</li>
       <li>klient planuje przeprowadzenie dużych transakcji gotówkowych (powyżej 10.000 EUR) lub już kilkakrotnie płacił rachunki na tak duże kwoty;</li>
     </ul>
     <p>Warto też zwrócić uwagę, czy płatność jest dokonywana za pośrednictwem banku znajdującego się na terytorium niepodlegającym opodatkowaniu.</p>
     <p><strong>Odmowa zawarcia umowy</strong></p>
     <p>Spółka ma prawo odmówić zawarcia transakcji lub umowy z klientem, jeżeli</p>
     <ul>
       <li>klient nie jest pełnoletni (18 lat zgodnie z obowiązującymi przepisami);</li>
       <li>upoważnieni przedstawiciele klienta (jeśli klient jest osobą fizyczną) próbują zamiast tego przeprowadzić transakcję;</li>
       <li>klient odmawia podania danych i dokumentów niezbędnych do potwierdzenia jego tożsamości lub podaje świadomie fałszywe informacje, które nie odpowiadają rzeczywistości;</li>
       <li>klient jest podejrzany o dokonywanie aktów prowokacyjnych;</li>
       <li>dokumentacja klienta jest sprawdzana pod kątem dowodów na pranie pieniędzy lub sponsorowanie organizacji terrorystycznych;</li>
       <li>klient został umieszczony na międzynarodowej liście sankcji;</li>
       <li>klient jest RER, jego partnerem lub członkiem rodziny osoby zajmującej eksponowane stanowisko polityczne;</li>
       <li>klient ma historię prania pieniędzy lub finansowego współudziału w terroryzmie;</li>
       <li>klient jest obywatelem kraju o wysokim ryzyku geograficznym;</li>
     </ul>
     <p>&nbsp;Każdy klient i podane przez niego informacje są sprawdzane w celu identyfikacji użytkowników z powyższej listy.</p>
     <p><strong>Zbieranie, rejestrowanie i przechowywanie informacji</strong></p>
     <p>Niezwłocznie po zawarciu transakcji przez klienta, funkcjonariusz jest zobowiązany do zebrania i zapisania wszystkich informacji dotyczących stron transakcji oraz jej parametrów. W szczególności należy odnotować następujące informacje:</p>
     <ul>
       <li>dane klienta, zgodnie z procedurą identyfikacji;</li>
       <li>gdy transakcja ma miejsce;</li>
       <li>treść transakcji;</li>
       <li>informacje o odmowie realizacji transakcji, jeśli taka miała miejsce (niezależnie od tego, czy została zainicjowana przez firmę, czy przez klienta);</li>
       <li>dane o zakończeniu współpracy, w tym z powodu braku możliwości weryfikacji prawnej;</li>
       <li>informacje o serwisie kantoru i aktualnym w chwili dokonywania transakcji kursie walut użytych w transakcji, a także o kwocie;</li>
       <li>jeśli klient otworzył konto w kryptowalucie, wskazany jest również rodzaj waluty, numer konta oraz nazwa waluty.</li>
     </ul>
     <p>Cała dokumentacja danej transakcji ma być przechowywana przez firmę przez okres 5 lat. Na tej liście znalazły się takie dokumenty:</p>
     <ul>
       <li>dane osobowe klienta wykorzystywane w celach identyfikacyjnych;</li>
       <li>pism, zawiadomień i innej korespondencji, które zostały wysłane lub otrzymane przez klienta;</li>
       <li>informacje zebrane przez pracowników firmy w trakcie sprawdzania klienta, monitorowania jego działań i jego relacji z firmą;</li>
       <li>zweryfikowane dane dotyczące podejrzanych transakcji;</li>
       <li>dokumentację przeprowadzonych transakcji.</li>
     </ul>
     <p>Podczas zbierania, klasyfikowania i przechowywania dokumentacji pracownicy muszą działać zgodnie z metodami ochrony danych osobowych i wykorzystywać uzyskane informacje wyłącznie w celu zwalczania prania pieniędzy i współudziału finansowego z organizacjami terrorystycznymi.</p>
     <p><strong>Procedury powiadamiania jednostki monitorującej finanse w razie potrzeby</strong></p>
     <p>Podczas sprawdzania dokumentacji klientów można znaleźć wiarygodne informacje o udziale w praniu pieniędzy lub udziale w finansowaniu transakcji terrorystycznych. W takim przypadku pracownik, który zidentyfikuje podejrzenie lub stwierdzi bezsporne fakty na to wskazujące, musi poinformować o tym fakcie odpowiedniego specjalistę ds. przeciwdziałania praniu pieniędzy (MLRO). Klient nie zostanie poinformowany o ujawnieniu MLRO. Przy zgłoszeniu należy dołączyć wszystkie niezbędne dokumenty dotyczące transakcji, dane osobowe klienta służące do jego identyfikacji oraz inne ewentualne dokumenty potwierdzające podejrzenia pracownika.</p>
     <p><strong>Wdrożenie kontroli wewnętrznych i szkoleń</strong></p>
     <p>Zarząd spółki odgrywa wiodącą rolę w zapewnieniu zgodności z ustawą o sankcjach międzynarodowych i prawem AML. Wybiera i szkoli pracowników, którzy przeprowadzają oceny ryzyka i sprawdzają klientów pod kątem prania pieniędzy. Kierownictwo odpowiada również za tych pracowników, którzy mają bezpośredni kontakt z klientami i przeprowadzają z nimi transakcje. Zarząd zapewnia, że pracownicy otrzymują wysokiej jakości szkolenia i edukację wymaganą przez prawo przynajmniej raz w roku.</p></div>
 </div>      
</div>
)
}
export default AML;