import React, { useEffect } from "react";
import ShopSection from "../components/homeComponents/shopSection";

const HomeScreen = (props) => {
	useEffect(()=>{
		window.scroll(0,0);
	},[])
	
	return (
		<div className="main-content">
			<div className="container">
				<ShopSection />
				<div className="main-page-steps">
					<div className="left">
						<img src="/res/dotsatoshi/img/main-page-app.png" alt="" />
						<div className="left-bottom">
						</div>
					</div>
					<div className="right">
						<h2 className="title">Jak dokonać wymiany</h2>
						<div className="right-steps">
							<div className="item">
								<div className="item-info uppercase">Krok 1</div>
								<p>Wybierz parę kryptowalut <br /> i kwotę do wymiany</p>
							</div>
							<div className="item">
								<div className="item-info uppercase">Krok 2</div>
								<p>Wpisz adres portfela odbiorcy</p>
							</div>
							<div className="item">
								<div className="item-info uppercase">Krok 3</div>
								<p>Wyślij tokeny, aby kontynuować wymianę</p>
							</div>
							<div className="item">
								<div className="item-info uppercase">Krok 4</div>
								<p>Zdobądź wybraną przez siebie walutę cyfrową</p>
							</div>
						</div>
					</div>
				</div>  <div className="main-page-partners">
					<h2 className="title">Nasi partnerzy</h2>
					<div className="partners-items">
						
						<div className="col">
							<div className="item bg-opacity">
								<div className="item-img"><a href="https://www.binance.com" title="Serwis umożliwiający znalezienie korzystnego kursu walutowego"><img src="/res/dotsatoshi/img/3.png" style={{ width: '200px' }} border={0} alt="Binance"/></a></div>
								<h4>Binance</h4>
								<div className="item-content">
									<p /><p>Monitoring wymienników Binance jest tworzony przez profesjonalistów w dziedzinie elektronicznych systemów płatności. Pomaga śledzić w czasie rzeczywistym kursy wymiany różnych walut elektronicznych wśród najlepszych e-kantorów. Jeśli chcesz zostawić recenzję naszej pracy lub przeczytać opinie innych użytkowników na temat Binance, zapraszamy do tego miejsca.</p><p />
								</div>
							</div>
						</div>
						
						<div className="col">
							<div className="item bg-opacity">
								<div className="item-img"><a href="https://walutobox.pl" target="_blank"><img src="/res/dotsatoshi/img/okchanger.png" alt="Walutobox" /></a></div>
								<h4>WalutoBox</h4>
								<div className="item-content">
									<p /><p>Monitoring kursów walutowych WalutoBox pozwala na szybkie znalezienie kantoru (OE) na podstawie określonych parametrów: miejsca docelowego, kursu walutowego, dostępnej rezerwy walutowej itp. Dane dotyczące usług i celów podróży reprezentowanych w monitoringu są stale monitorowane i aktualizowane.</p><p />
								</div>
							</div>
						</div>
					</div>
				</div>  <div className="main-page-reviews">
					<div className="title-block">
						<h2 className="title">Świadectwa dla nas</h2>
						<div className="right">
							<a href="/reviews/" className="btn">Wyświetl wszystkie opinie</a>
							
						</div>
					</div>
					<div className="review-items">
						<div className="col">
							<div className="item bg-opacity">
								<div className="item-top">
									<h4>Bolesław</h4>
									<div className="revRait review-rating rait-5-stars">
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
									</div>
								</div>
								<div className="item-content">
									<p>Wszystko dotarło, bardzo zadowoleni, szybko i sprawnie reagują, polecam</p>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="item bg-opacity">
								<div className="item-top">
									<h4>Bronisław</h4>
									<div className="revRait review-rating rait-5-stars">
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
									</div>
								</div>
								<div className="item-content">
									<p>Wymiana zakończyła się sukcesem. Szybciej niż myślałem. Dziękuję za szybką reakcję. Polecam.</p>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="item bg-opacity">
								<div className="item-top">
									<h4>Kazimierz</h4>
									<div className="revRait review-rating rait-5-stars">
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
									</div>
								</div>
								<div className="item-content">
									<p>Jakość usług, zmień to, nie będziesz żałował</p>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="item bg-opacity">
								<div className="item-top">
									<h4>Lesław</h4>
									<div className="revRait review-rating rait-5-stars">
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
										<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.0002 17.75L5.82816 20.995L7.00716 14.122L2.01416 9.255L8.91416 8.253L12.0002 2L15.0862 8.253L21.9862 9.255L16.9932 14.122L18.1722 20.995L12.0002 17.75Z" fill="#FF006B" />
										</svg>
									</div>
								</div>
								<div className="item-content">
									<p>Sprzedałem kryptowaluty za dolary bez żadnych problemów</p>
								</div>
							</div>
						</div>
					</div>
					<div className="main-reviews-bottom">
						<a href="/reviews/" className="btn">Wyświetl wszystkie opinie</a>
						
					</div>
				</div>
				
			</div>
			
		</div>








	);
};

export default HomeScreen;
