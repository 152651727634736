import React from "react";

const Api=()=>{
return(<div className="content">
<div className="content">
  <div className="container">
    <div className="content-top cabinet-top">
      <h1 className="title">Szafka osobista</h1>
      <div className="cabinet-top-right">
        <div className="item">
        Twój rabat          <span>0%</span>
        </div>
        <div className="item">
        Saldo poleceń        <span>0$</span>
        </div>
      </div>
    </div>
    <div className="content-wrapper cabinet-content bg-opacity">
      <div className="cabinet-content-wrapper">
        <div className="cabinet-content-left">
          <div className="cabinet-api">
            <h2>API do podłączenia naszej usługi</h2>
            <p>Jeśli chcesz uzyskać dostęp do API naszego serwisu, napisz do nas na adres</p>
            <a href="mailto:api@dotsatoshi.top" className="btn">api@dotsatoshi.top</a>
          </div>      </div>
        <div className="cabinet-content-right">
          <div className="cabinet-menu">
            <a href="/account/orders/" className>Historia transakcji</a>
            <a href="/account/referals/" className>Program skierowań</a>
            <a href="/account/api/" className="active">API</a>
            <a href="/account/settings/" className>Ustawienia</a>
            <a href="/account/verification/" className>Weryfikacja</a>
            <a href="/account/balance/" className>Równowaga</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
)
}
export default Api;