import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import uniqid from "uniqid";
import { listProductReceive } from "../Redux/Actions/productActions";
import { useDispatch } from "react-redux";
const Reserve = () => {
    const dispatch = useDispatch();
    const productRecieveList = useSelector((state) => state.productRecieveList);
    const [pmSelectTypeReceive, setTypeReceive] = useState("All");
    const { loadingReceive, errorReceive, productsReceive } = productRecieveList;
    useEffect(() => {
        dispatch(listProductReceive());
    }, [dispatch]);
    
    return (<div className="content">
        <div className="container">
            <div className="content-top ">
                <h1 className="title">Nasze rezerwy</h1>
            </div>
            <div className="content-wrapper bg-opacity reserve-wrapper">
                <div className="reserve-wrapper-top">
                    <a href="javascript:void(0);" className={`psBtn ${pmSelectTypeReceive == "All" ? "active" : ""}`} onClick={() => setTypeReceive("All")}>Wszystkie</a>
                    <a href="javascript:void(0);" className={`psBtn ${pmSelectTypeReceive == "crypto" ? "active" : ""}`} onClick={() => setTypeReceive("crypto")}>Kryptowaluty</a>
                    <a href="javascript:void(0);" className={`psBtn ${pmSelectTypeReceive == "bank" ? "active" : ""}`} onClick={() => setTypeReceive("bank")}>Banki</a>
                    <a href="javascript:void(0);" className={`psBtn ${pmSelectTypeReceive == "eps" ? "active" : ""}`} onClick={() => setTypeReceive("eps")}>EPS</a>
                </div>
                <div className="reserve-wrapper-items">
                    {!loadingReceive ? productsReceive.filter((item) => {
                        switch (pmSelectTypeReceive) {
                            case "bank":
                                if (item.banking) {
                                    return item;
                                }
                                break;
                            case "eps":
                                if (item.eps) {
                                    return item;
                                }
                                break;
                            case "crypto":
                                if (!item.efs && !item.banking && !item.fiat) {
                                    return item;
                                }
                                break;
                            default: return item

                        }

                    }).map(item => <div key={uniqid()} className="item">
                        <div className="item-img">
                            <img width={30} height={30} alt={item.titleReceive} src={item.imageReceive} />
                        </div>
                        <div className="item-title">{item.titleReceive}</div>
                        <span>{item.reserve} {item.symbolReceive}</span>
                    </div>) : null}


                </div></div></div>
        </div>
    );
}
export default Reserve