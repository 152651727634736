import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import mobxStore from "../../mobx/mobxStore";
import Toast from "../LoadingError/toast";
import { toast } from "react-toastify";
import axios from "axios";
import { serverLink } from "../../App";
const ForgotPassword = ({ location, history }) => {
    const Toastobjects = {
        pauseOnFocusLoss: false,
        draggable: false,
        pauseOnHover: false,
        autoClose: 2000,
    };
    const toastId = React.useRef(null);
    const [email, setEmail] = useState("");
    const { variables, signInChange,forgotChange } = mobxStore;
    const [mailSent,setMailSent]=useState(false);
     const submitHandler = async(e) => {
        e.preventDefault();
        if(validateEmail(email)){
                setMailSent(true);
                await axios.post(`${serverLink}/api/users/forgotpassword?email=${email}`)
        }

    };
    return (
        <React.Fragment>
            <div className="mfp-bg mfp-ready" />
            <div
                className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                tabIndex={-1}
                style={{ overflow: "hidden auto" }}
            >
                <div className="mfp-container mfp-inline-holder">
                    <div className="mfp-content">
                        <div
                            className="modal password-reset-modal"
                            id="password-reset-modal"
                        >
                            <div className="modal-wrapper">
                                <h2 className="modal-title">
                                Odzyskiwanie hasła
                                </h2>
                                <div id="resetPassword">
                                    <div className="input-group">
                                        <input
                                            className="form-control f-input"
                                            placeholder="E-mail"
                                            type="text"
                                            name="email"
                                            autoComplete="off"
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            value={email}
                                        />
                                    </div>
                                            {mailSent?<div className="user-security-check-lostpass">
                                        <div className="user-security-codes">
                                            <div className="alert alert-warning">
                                                Nowe hasło zostało wysłane na
                                                Twój adres e-mail. Sprawdź swoją
                                                skrzynkę e-mail:
                                            </div>{" "}
                                        </div>
                                    </div>:null}
                                    
                                    
                                    <div className="form-bottom">
                                        <button
                                            
                                            id="restore-btn"
                                            className="btn"
                                            onClick={(e)=>submitHandler(e)}
                                        >
                                            Resetowanie hasła
                                        </button>
                                        <a
                                            href="#login-modal"
                                            className="btn btn-white popup-modal"
                                            onClick={()=>{
                                                forgotChange(false);
                                                signInChange(true)
                                            }}
                                        >
                                            Zaloguj się
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <button
                                title="Close (Esc)"
                                type="button"
                                className="mfp-close"
                                onClick={()=>{
                                  forgotChange(false);      
                                }}
                            >
                                ×
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ForgotPassword;

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
