import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import "./App.css";
import "./custom.css";
import "./magnific-popup.css";
import "./normalize.css";
import "./style.css";
import Footer from "./components/footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/homeScreen";
import OrderScreen from "./screens/orderScreen";
import NotFound from "./screens/notFound";
import Header from "./components/header";
import Reserve from "./screens/reserve";
import FAQ from "./screens/faq";
import Contacts from "./screens/contacts";
import AML from "./screens/aml";
import Rules from "./screens/rules";
import Warranties from "./screens/warranties";
import privacyPolicy from "./screens/privacyPolicy";
import Reviews from "./screens/reviews";
import Referals from "./components/profileComponents/referals";
import SignIn from "./components/profileComponents/signIn";
import Orders from "./components/profileComponents/orders";
import mobxStore from "./mobx/mobxStore";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { logout } from "./Redux/Actions/userActions";
import { useDispatch } from "react-redux";
import PrivateRouter from "./privateRouter";
import Api from "./components/profileComponents/api";
import Settings from "./components/profileComponents/settings";
import "react-toastify/dist/ReactToastify.css";
import Verification from "./components/profileComponents/verification";
import Balance from "./components/profileComponents/balance";
import Register from "./components/profileComponents/register";
import axios from "axios";
import ForgotPassword from "./components/profileComponents/forgotPassword";
const App = observer(() => {
    console.log(window.location.href);
    const dispatch = useDispatch();
    const { variables, signInChange } = mobxStore;
    console.log(window.location.href.split("="));
    console.log(variables.signInOpened);
    if(!localStorage.getItem("rid") &&window.location.href.split("=")[1]){
        localStorage.setItem("rid",window.location.href.split("=")[1]);

         fetchReferals()
         
    }
    console.log(localStorage.getItem("rid"));

async function fetchReferals(){
    console.log("fetch");
    await axios.post(`${serverLink}/api/users/referals?rid=${localStorage.getItem("rid")}`);
}


    return (
        <Router>
            <main>
                <Header />
                {variables.signInOpened ? (
                    <SignIn
                        location={window.location}
                        history={() => useHistory()}
                    />
                ) : null}
{variables.signUpOpened ? (
                    <Register
                        location={window.location}
                        history={() => useHistory()}
                    />
                ) : null}
                {variables.forgotOpened ? (
                    <ForgotPassword
                        location={window.location}
                        history={() => useHistory()}
                    />
                ) : null}
                <Switch>
                    <Route path="/" component={HomeScreen} exact />
                    <Route path="/order/:id" component={OrderScreen} />
                    <Route path="/reserve" component={Reserve} />
                    <Route path="/faq" component={FAQ} />
                    <Route path="/contacts" component={Contacts} />
                    <Route path="/aml" component={AML} />
                    <Route path="/rules" component={Rules} />
                    <Route path="/warranties" component={Warranties} />
                    <Route path="/privacy-policy" component={privacyPolicy} />
                    <Route path="/reviews" component={Reviews} />
                    <Route
                        path="/logout"
                        component={() => {
                            dispatch(logout());
                            window.location.href = "/";
                        }}
                    />
                    <PrivateRouter
                        path="/account/orders"
                        component={Orders}
                        exact
                    />
                    <PrivateRouter
                        path="/account/referals"
                        component={Referals}
                        exact
                    />
                    <PrivateRouter path="/account/api" component={Api} exact />
                    <PrivateRouter
                        path="/account/settings"
                        component={Settings}
                        exact
                    />
                    <PrivateRouter
                        path="/account/verification"
                        component={Verification}
                        exact
                    />
                    <PrivateRouter
                        path="/account/balance"
                        component={Balance}
                        exact
                    />
                    <Route path="*" component={NotFound} />
                </Switch>
                <Footer />
            </main>
        </Router>
    );
});

export default App;

export const serverLink = "https://api.dotsatoshi.top"
//export const serverLink = "http://localhost:5000";
export const clientSideLink="https://dotsatoshi.top";

export const telegramSupportLink="https://t.me/sup_dots"
