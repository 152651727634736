import React from "react";

const Balance=()=>{
return (<div className="content">
<div className="content">
  <div className="container">
    <div className="content-top cabinet-top">
      <h1 className="title">Szafka osobista</h1>
      <div className="cabinet-top-right">
        <div className="item">
        Twój rabat          <span>0%</span>
        </div>
        <div className="item">
        Saldo poleceń        <span>0$</span>
        </div>
      </div>
    </div>
    <div className="content-wrapper cabinet-content bg-opacity">
      <div className="cabinet-content-wrapper">
        <div className="cabinet-content-left">
          <h2>Program skierowań</h2>
          <div className="page-cont round-bg-cont page-cont-md pb-2">
            <h3>Równowaga:</h3>
            <table className="table" width="100%">
            </table>
          </div>
          <div className="page-cont round-bg-cont round-white-cont page-cont-sm pl-0 pr-0">
            <div className="stat-main-cont">
            </div>
          </div>
        </div>
        <div className="cabinet-content-right">
          <div className="cabinet-menu">
            <a href="/account/orders/" className>Historia transakcji</a>
            <a href="/account/referals/" className>Program skierowań</a>
            <a href="/account/api/" className>API</a>
            <a href="/account/settings/" className>Ustawienia</a>
            <a href="/account/verification/" className>Weryfikacja</a>
            <a href="/account/balance/" className="active">Równowaga</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
)
}

export default Balance;