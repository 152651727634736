import React from "react";

const Hero =()=>{
    return (<div className="main-left s1">
    <h1>Usługa konwersji walut cyfrowych</h1>
    <div className="main-preim-items">
      <div className="item">
        <div className="item-icon">
          <img src="/res/dotsatoshi/img/main-icon-1.svg" alt="i1" />
        </div>
        <p>Nie wymaga rejestracji ani konta</p>
      </div>
      <div className="item">
        <div className="item-icon">
          <img src="/res/dotsatoshi/img/main-icon-2.svg" alt="i2" />
        </div>
        <p>Twoje bezpieczeństwo jest naszym celem</p>
      </div>
      <div className="item">
        <div className="item-icon">
          <img src="/res/dotsatoshi/img/main-icon-3.svg" alt="i3" />
        </div>
        <p>Korzyści wynikające z programów współpracy</p>
      </div>
    </div>
    
  </div>)
}

export default Hero;