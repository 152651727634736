import React, { useState } from "react";
import { logout } from "../Redux/Actions/userActions";
import mobxStore from "../mobx/mobxStore";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Header = () => {
    const { signInChange,signUpChange } = mobxStore;
    let location = window.location.pathname;
    const [modalOpened, setOpened] = useState(false);
    const [profileOpened,setProfileOpened]=useState(false);
    const [signInOpened, setSignInOpened] = useState(false);
    const userLogin = useSelector((state) => state.userLogin);
    const { error, userInfo } = userLogin;

    console.log(JSON.parse( localStorage.getItem("userInfo")));

    return (
        <React.Fragment>
            <header>
                <div className="container">
                    <div className="top">
                        <a
                            href="/#"
                            className="mob-menu-btn"
                            onClick={() => setOpened(true)}
                            style={{ fontSize: "0px" }}
                        >
                            .
                        </a>
                        <div className="logo">
                            <a href="/">
                                <img
                                    src="/res/dotsatoshi/img/logo.svg"
                                    alt="logo"
                                />
                            </a>
                        </div>
                        <ul className="menu">
                            <li
                                className={
                                    !location.includes("reserve") &&
                                    !location.includes("faq") &&
                                    !location.includes("contacts")
                                        ? "active"
                                        : ""
                                }
                            >
                                <a href="/">Wymiana</a>
                            </li>

                            <li
                                className={
                                    location.includes("reserve") ? "active" : ""
                                }
                            >
                                <a href="/reserve/">Rezerwy walutowe</a>
                            </li>
                            <li
                                className={
                                    location.includes("faq") ? "active" : ""
                                }
                            >
                                <a href="/faq/">FAQ</a>
                            </li>
                            <li
                                className={
                                    location.includes("contacts")
                                        ? "active"
                                        : ""
                                }
                            >
                                <a href="/contacts/">Informacja zwrotna</a>
                            </li>
                        </ul>
                        {userInfo ? (
                            <div className="top-account" style={{display:`${profileOpened? "block":""}`}}>
                                <a
                                    href="/account/orders/"
                                    className="fBtn userAuthorized"
                                >
                                    <img
                                        src="/res/dotsatoshi/img/user.svg"
                                        alt="user"
                                    />
                                    <div>
                                        <b>Szafka osobista</b>
                                        <div className="mail">
                                            {userInfo.email}
                                        </div>
                                    </div>
                                </a>
                                <Link
                                    to="/logout"
                                    className="top-register"
                                >
                                    Wyjście
                                </Link>
                            </div>
                        ) : (
                            <div className="top-account" style={{display:`${profileOpened? "block":""}`}}>
                                <a
                                    href="#login-modal"
                                    className="btn popup-modal"
                                    onClick={() => signInChange(true)}
                                >
                                    Logowanie
                                </a>
                                <a
                                    href="#register-modal"
                                    className="top-register popup-modal"
                                    onClick={() => signUpChange(true)}
                                >
                                    Rejestracja
                                </a>
                            </div>
                        )}

                        <div className="top-lang">
                            <a href="/#" className="top-lang-btn">
                                <img
                                    src="/res/default/i/flags/pl.png"
                                    alt="lang"
                                />
                            </a>
                        </div>
                        <a href="#" className="mob-account-btn" onClick={()=>setProfileOpened(!profileOpened)}/>

                    </div>
                </div>
            </header>
            <div className={`mob-menu ${modalOpened ? "active" : ""}`}>
                <div className="mob-menu-top">
                    <a
                        href="/#"
                        className="mob-menu-close"
                        onClick={() => setOpened(false)}
                        style={{ fontSize: "0px" }}
                    >
                        .
                    </a>
                    <a href="/#" className="mob-menu-logo">
                        <img src="/res/dotsatoshi/img/logo.svg" alt="" />
                    </a>
                </div>
                <div className="mob-menu-wrapper">
                    <ul className="mob-top-menu">
                        <li>
                            <a href="/">Wymiana</a>
                        </li>

                        <li>
                            <a href="/reserve/">Rezerwy walutowe</a>
                        </li>
                        <li>
                            <a href="/faq/">FAQ</a>
                        </li>
                        <li className="active">
                            <a href="/contacts/">Informacja zwrotna</a>
                        </li>
                    </ul>
                    <ul className="mob-bottom-menu">
                        <li>
                            <a href="/rules/">Zasady obsługi</a>
                        </li>
                        <li>
                            <a href="/privacy-policy/">Prywatność</a>
                        </li>
                        <li>
                            <a href="/aml/">AML</a>
                        </li>
                        <li>
                            <a href="/reviews/">Recenzje</a>
                        </li>
                        <li>
                            <a href="/warranties/">Gwarancje niezawodności</a>
                        </li>

                        <li>
                            <a
                                href="https://t.me/HelpSupporty"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Jesteśmy na Telegramie
                            </a>
                        </li>
                    </ul>
                    <div className="mob-app-links"></div>
                    <p className="mob-copyright">
                        Copyright © 2021 DotSatoshi.{" "}
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Header;
