import React from "react";

const Rules=()=>{
    return(<div className="content">
    <div className="container">
      <div className="content-top">
        <h1 className="title">Regulamin giełdy internetowej DotSatoshi</h1>
      </div>
      <div className="content-wrapper bg-opacity">
        <p><strong>1. Przepisy ogólne</strong></p>
        <p>1.1. Niniejsza umowa określa procedury świadczenia usług wymiany pieniądza elektronicznego przez serwis internetowy.</p>
        <p>1.2. Serwis internetowy dotyczy internetowego serwisu wymiany elektronicznych i cyfrowych pieniędzy DotSatoshi.top.</p>
        <p>1.3. Użytkownik to osoba fizyczna lub prawna, która wyraziła chęć korzystania z serwisu internetowego DotSatoshi.</p>
        <p>1.4. Użytkownik i zasób internetowy DotSatoshi są określani łącznie jako Strony.</p>
        <p>1.5. Strony zgadzają się, że niniejsza Umowa, zawarta w formie elektronicznej, ma taką samą moc prawną jak dokument papierowy i będzie regulować stosunki pomiędzy Użytkownikami (osobą prawną lub fizyczną) a Usługodawcą (DotSatoshi).</p>
        <p>1.6. Umowa - oferta publiczna, którą Użytkownik rejestruje poprzez złożenie wniosku za pośrednictwem serwisu DotSatoshi na usługi świadczone przez serwis DotSatoshi.</p>
        <p>&nbsp;</p>
        <p><strong>2. Przedmiot umowy</strong></p>
        <p>2.1. Serwis DotSatoshi świadczy usługi opisane w punkcie 4 niniejszej Umowy, pod warunkiem, że Użytkownik będzie przestrzegał i stosował się do standardów określonych w punkcie 9 niniejszej Umowy. Tryb ich udostępniania określają wewnętrzne regulacje serwisu DotSatoshi określone w punkcie 5 niniejszej Umowy.</p>
        <p>2.2. Użytkownik korzysta z usług serwisu DotSatoshi w celu dokonywania płatności na swój koszt, na zasadach określonych w Umowie.</p>
        <p>&nbsp;</p>
        <p><strong>3. Prawa i obowiązki stron</strong></p>
        <p><strong>3.1. Serwis internetowy DotSatoshi jest zobowiązany:</strong></p>
        <p>3.1.1. Wykonywanie przeliczeń walut wirtualnych Qiwi, Payeer, Perfect Money, Yandex.Money i innych portfeli internetowych, z zastrzeżeniem standardów określonych w niniejszej umowie.</p>
        <p>3.1.2. Udzielania Użytkownikom wszelkiego wsparcia (technicznego i informacyjnego) w procesie przeprowadzania transakcji pieniężnych z wykorzystaniem Serwisu Internetowego DotSatoshi.</p>
        <p>3.1.3. Zachować anonimowość szczegółowych informacji o dokonanych transakcjach wymiany (dane osobowe Użytkowników, kiedy została dokonana transakcja wymiany, jej kwota, inne informacje) i udostępnić je tylko Użytkownikom, którzy dokonali tych transakcji. Dane o transakcjach pieniężnych w różnych kierunkach z wykorzystaniem systemów usług płatniczych mają status "Poufne" i nie podlegają ujawnieniu.</p>
        <p>3.1.4. Nie przekazuj informacji o zrealizowanych transakcjach osobom trzecim. Wyjątkiem mogą być takie przypadki:</p>
        <ul>
          <li>jeżeli sąd znajdujący się w lokalizacji serwisu internetowego DotSatoshi wydał w tym zakresie postanowienie, które weszło w życie;</li>
          <li>na żądanie oficjalnych organów ścigania i instytucji śledzących finanse, wykonujących swoją pracę w miejscu świadczenia usługi internetowej DotSatoshi;</li>
          <li>w przypadku kontaktu z menedżerami firm partnerskich (systemów płatności online) wymienionych powyżej.</li>
        </ul>
        <p>3.1.5. Rejestrowanie danych dotyczących premii i rabatów wydawanych Użytkownikom.</p>
        <p>3.1.6. Zapewnienie przekazania środków na konto Użytkownika lub osoby trzeciej w ciągu 24 godzin od otrzymania reklamacji w przypadkach określonych w pkt 3.2.5, 5.4, 5.5, 5.6 niniejszej Umowy.</p>
        <p>&nbsp;</p>
        <p><strong>3.2. Użytkownik musi:</strong></p>
        <p>3.2.1. Dostarczanie dokładnych informacji i szczegółów dla szybkich transakcji gotówkowych.</p>
        <p>3.2.2. Pokaż dokładne dane dotyczące użytego adresu e-mail.</p>
        <p>3.2.3. Upewnij się, że masz możliwość odbierania wiadomości systemowych na swoim internetowym koncie e-mail. Posiadanie dostępu do Internetu za pomocą komputera, smartfona lub innego sprzętu elektronicznego. Używaj najnowszych wersji oprogramowania antywirusowego, aby nawiązać bezpieczne połączenie z zasobem internetowym DotSatoshi.</p>
        <p>3.2.4. Ścisłe przestrzeganie wszystkich warunków niniejszej Umowy.</p>
        <p>3.2.5. Powiadomić administrację serwisu DotSatoshi o wszelkich przypadkach, w których przekazane kwoty nie zostały w całości lub w części zaksięgowane na koncie Beneficjenta lub osoby trzeciej. Jak również precedensów wymienionych w punktach 5.4, 5.5 i 5.6 niniejszej Umowy. Niezbędne zawiadomienie jest wysyłane do administracji w ciągu jednego miesiąca od momentu, gdy środki stały się wymagalne. Jeśli te warunki nie zostaną spełnione, wszystkie sporne kwoty pozostają do dyspozycji DotSatoshi Internet Service.</p>
        <p>3.2.6. Przestrzegaj przepisów, które regulują zasady wszelkich transakcji pieniężnych w sieci.</p>
        <p>3.2.7. Nie używaj żadnego systemu do napędzania ruchu.</p>
        <p>&nbsp;</p>
        <p><strong>3.3. Serwis internetowy DotSatoshi ma prawo:</strong></p>
        <p>3.3.1. Czasowe zawieszenie działalności w celu modernizacji usługi internetowej lub usunięcia awarii.</p>
        <p>3.3.2. W przypadku kontaktu ze strony uprawnionych organizacji, kierownictwa systemów płatniczych wymienionych powyżej lub w przypadku zgłoszenia przez Użytkownika skargi na jakiekolwiek bezprawne (oszukańcze) działania, wstrzymanie transakcji wymiany do czasu wyjaśnienia wszystkich niuansów.</p>
        <p>3.3.3. Określa, według własnego uznania, rabaty dla transakcji wymiany.</p>
        <p>3.3.4. Ustawienie kwoty prowizji, która ma być odliczana za zrealizowaną transakcję wymiany.</p>
        <p>3.3.5. Odmówić świadczenia usług ewentualnemu Użytkownikowi, nie wyjaśniając swoich działań i motywów.</p>
        <p>3.3.6. Otrzymać od Użytkownika dane potwierdzające transakcję wymiany poprzez e-mail, smartfon, zrzut ekranu z e-portfela w przypadku, gdy wymiana została zakończona błędem.</p>
        <p>3.3.7. Przerwij wszelką komunikację z użytkownikiem, jeśli jest on nieuprzejmy, zadaje pytania nie związane z tematem dyskusji lub nie przekazuje właściwych informacji do helpdesku.</p>
        <p>3.3.8. Zablokować realizację transakcji wymiany, powołując się na klauzule 5.4, 5.5, 5.6 oraz Regulamin.</p>
        <p>3.3.9. Zablokuj każdą transakcję i nie wydawaj pieniędzy użytkownikowi, dopóki jego tożsamość nie zostanie zidentyfikowana.</p>
        <p>3.3.10. W przypadku nieprzestrzegania obowiązków określonych w punkcie 3.2.7, Administracja ma prawo zablokować Użytkownika naruszającego ten punkt, jak również źródło / generator skoków.</p>
        <p>3.3.11. W przypadku bezpodstawnego wzbogacenia Użytkownika o pieniądz elektroniczny Serwisu Internetowego DotSatoshi, lub systemów wymiany pieniądza elektronicznego podmiotów trzecich. Administracja serwisu giełdowego dotsatoshi.top ma prawo wstrzymać transakcję do czasu wyjaśnienia wszystkich przyczyn i okoliczności, a także zrekompensować bezpodstawnie otrzymane środki oraz prawa majątkowe do nich serwisu internetowego DotSatoshi lub serwisów giełdowych osób trzecich.</p>
        <p>&nbsp;</p>
        <p><strong>4. Świadczone usługi</strong></p>
        <p>4.1. Serwis internetowy DotSatoshi realizuje wymianę pieniędzy elektronicznych, takich systemów płatności jak:&nbsp;Bitcoin, Qiwi, Payeer, Perfect Money, Yandex.Money i innych, z wykorzystaniem instytucji bankowych, których lista umieszczona jest na stronie.</p>
        <p>4.2. Serwis internetowy DotSatoshi nie jest zobowiązany do weryfikacji legalności posiadania przez Użytkownika środków wykorzystanych w transakcjach.</p>
        <p>&nbsp;</p>
        <p><strong>5. Regulacje dotyczące wymiany pieniądza elektronicznego</strong></p>
        <p>5.1. Transakcje są inicjowane dopiero po wpłynięciu środków Użytkownika na wymianę. Jeśli środki nie zostaną zaksięgowane w ciągu 20 minut, wniosek zostanie automatycznie anulowany przez serwis bezpieczeństwa DotSatoshi. Jeżeli płatność wpłynie po określonym czasie (90 minut dla kryptowalut), zostanie ona zrealizowana po kursie walutowym aktualnym w momencie zaksięgowania pieniędzy od Użytkownika.</p>
        <p>5.2. Transakcję uważa się za zakończoną, gdy środki zostały przekazane na rachunek wskazany przez Użytkownika.</p>
        <p>5.3. Użytkownik nie może przerwać rozpoczętej transakcji ani zwrócić pieniędzy wysłanych na wymianę.</p>
        <p>5.4. W przypadku otrzymania kwoty niezgodnej z kwotą podaną we wniosku, Serwis Internetowy DotSatoshi może opóźnić wymianę i przeliczyć transakcję zgodnie z faktycznie otrzymanymi środkami. Po skontaktowaniu się przez Użytkownika z Zespołem Wsparcia, DotSatoshi wypłaci kwotę faktycznie zakredytowaną po kursie wymiany obowiązującym w momencie dokonywania transakcji, zgodnie z punktem 3.2.5.</p>
        <p>5.5. Jeśli Użytkownik podał nieprawidłowe lub zablokowane dane, operacja wymiany zostaje przerwana. Środki te wpływają na konto Użytkownika na podstawie jego zgłoszenia do usługi wsparcia technicznego zgodnie z pkt 3.2.5.</p>
        <p>5.6. Jeżeli Użytkownik zmienił noty do płatności lub dokonał płatności z innego konta, transakcja może zostać zablokowana. W takiej sytuacji zwrot pieniędzy nastąpi po zgłoszeniu się Użytkownika zgodnie z pkt 3.2.5.</p>
        <p>5.7. Jeśli klient otrzyma kryptowalutę Bitcoin, to moment wystawienia dowodu transakcji (otrzymania środków) jest całkowicie zależny od systemu Bitcoin. Proces ten może trwać od 15 minut do kilku dni, w zależności od tego, jak bardzo zajęta jest sieć Bitcoin. Serwis wymiany&nbsp;nie odpowiada za szybkość transakcji i potwierdzeń z sieci Bitcoin.</p>
        <p>5.8 W każdym przypadku dokonania zwrotu pobierana jest opłata w wysokości 5%, z wyjątkiem punktu 9.9.</p>
        <p>&nbsp;</p>
        <p><strong>6. Gwarancje i obowiązki stron</strong></p>
        <p>6.1. Serwis internetowy DotSatoshi nie ponosi odpowiedzialności finansowej i moralnej za nieumiejętne korzystanie przez Użytkownika z funkcji serwisu, jak również za błędne obliczenia dokonane przez Użytkownika przy wypełnianiu jakichkolwiek pozycji w standardowym formularzu wniosku o wymianę środków elektronicznych. Nawet jeśli pieniądze trafiły na niewłaściwe konto, nie ma mowy o anulowaniu transakcji czy zwrocie pieniędzy.</p>
        <p>6.2. Serwis Internetowy DotSatoshi nie ponosi odpowiedzialności za jakiekolwiek straty lub szkody wynikające z niemożności korzystania przez Użytkownika z całości lub części wymaganego sprzętu.</p>
        <p>6.3. Интернет-сервис DotSatoshi не отвечает за поступки банков и платежных систем, в результате которой произошли ошибки или задержки транзакции.</p>
        <p>6.4. Serwis Internetowy DotSatoshi nie ponosi odpowiedzialności za jakiekolwiek koszty, straty lub utracone korzyści przez Użytkownika wynikające z jego błędnych przekonań dotyczących opłat, opłacalności transakcji i innych subiektywnych aspektów.</p>
        <p>6.5. Serwis internetowy DotSatoshi nie ponosi odpowiedzialności za koszty o innym charakterze poniesione z powodu błędów/opóźnień w dokonywaniu przelewów lub płatności;</p>
        <p>6.6. Użytkownik udowadnia, że jest prawnym właścicielem środków, ma uzasadniony powód używania pieniądza elektronicznego, który bierze udział w transakcjach wymiany.</p>
        <p>6.7. Użytkownik zobowiązuje się do zwolnienia osób trzecich z odpowiedzialności za szkody powstałe w wyniku niewłaściwego wykorzystania funkcjonalności serwisu internetowego.</p>
        <p>6.8. Użytkownik gwarantuje, że w momencie korzystania z Serwisu jest pełnoletni zgodnie z prawem kraju, w którym znajduje się Użytkownik.</p>
        <p>&nbsp;</p>
        <p><strong>7. Zmiana informacji</strong></p>
        <p>7.1. Administracja serwisu internetowego DotSatoshi może w każdej chwili uzupełnić lub zmodyfikować niniejszą Umowę. Wszelkie zmiany wchodzą w życie z chwilą umieszczenia ich na stronie internetowej.</p>
        <p>&nbsp;</p>
        <p><strong>8. Siła wyższa</strong></p>
        <p>8.1. Strony Umowy są zwolnione z odpowiedzialności za niewykonanie/opóźnienie w wykonaniu podjętych zobowiązań w przypadku wystąpienia zdarzeń o charakterze siły wyższej. Dotyczą one: klęsk żywiołowych, działań władz, wybuchu działań wojennych, pożaru, wybuchu, ataku terrorystycznego, powodzi, zamieszek, ataku hakerów, masowych niepokojów społecznych. Obejmują one również brak lub awarię sieci energetycznej, ograniczony dostęp do internetu i usług komunikacyjnych, innych systemów i sieci.</p>
        <p>&nbsp;</p>
        <p><strong>9. Warunki obowiązkowe dla transakcji wymiany</strong></p>
        <p>9.1. Zabronione jest korzystanie z funkcji serwisu internetowego DotSatoshi związanych z działalnością nielegalną lub oszukańczą. Użytkownik zgadza się, że wszelkie próby wymiany pieniędzy elektronicznych wątpliwego pochodzenia będą karane zgodnie z prawem kraju, w którym dokonano transakcji. Będzie on podlegał środkom karnym zgodnie z prawem kraju, w którym dokonano transakcji wymiany.</p>
        <p>9.2. Serwis internetowy DotSatoshi ma prawo do przekazania danych oszukańczych, jeśli ich nielegalność zostanie należycie udowodniona, przez właściwe organizacje, administracje odpowiednich systemów płatniczych, roszczenia pokrzywdzonych.</p>
        <p>9.3. Warunkiem przeprowadzenia transakcji wymiany jest przekazanie środków z portfela elektronicznego należącego do Użytkownika. Użytkownik odpowiada jednak za legalność źródeł, z których pochodzą środki, gdyż Serwis Internetowy DotSatoshi nie jest zobowiązany do weryfikacji i kontroli ich pochodzenia.</p>
        <p>9.4. Serwis internetowy DotSatoshi nie ponosi odpowiedzialności za operacje wykonywane przez osoby trzecie w imieniu Użytkownika.</p>
        <p>9.5. Użytkownik, klikając przycisk "Zgadzam się na warunki wymiany", wyraża zgodę na wszystkie warunki niniejszej Umowy bez zastrzeżeń.</p>
        <p>9.6. Użytkownik, poprzez kliknięcie przycisku "Zgadzam się&nbsp; z regulaminem giełdy" wyraża bez zastrzeżeń zgodę na poddanie się procedurze weryfikacji na pierwsze żądanie administracji Serwisu Internetowego.</p>
        <p>9.7. Użytkownik poprzez identyfikację wyraża zgodę na przeprowadzenie przez Serwis Internetowy wszelkich badań nad Użytkownikiem, które Serwis Internetowy uzna za konieczne. Serwis internetowy może, według własnego uznania, zaangażować osobę trzecią do przeprowadzenia takich badań.</p>
        <p>9.8. Użytkownik, klikając na przycisk "Zgadzam się na warunki wymiany", wyraża wyraźną zgodę na sprawdzanie pod kątem AML wszystkich adresów wchodzących w interakcję z Serwisem, poprzez wykonywanie operacji utworzonych przez Użytkownika.</p>
        <p>9.9. Serwis internetowy ma prawo zażądać pełnej identyfikacji Użytkownika, jeżeli podany przez niego adres portfela może być w jakikolwiek sposób powiązany z Użytkownikiem:</p>
        <ul>
          <li>Darknet Marketplace;</li>
          <li>&nbsp;Fraudulent Exchange;</li>
          <li>Illegal Service;</li>
          <li>Mixing Service;</li>
          <li>Ransom;</li>
          <li>Scam;</li>
          <li>Stolen Coins.</li>
        </ul>
        <p>W przypadku wykrycia połączeń, środki zostaną zwrócone na konto nadawcy po weryfikacji (minus 10% prowizji).</p>
        <p>&nbsp;</p></div>
    </div>      
    </div>
  )
}

export default Rules;