import axios from "axios";
import {
    PRODUCT_CREATE_REVIEW_FAIL,
    PRODUCT_CREATE_REVIEW_REQUEST,
    PRODUCT_CREATE_REVIEW_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LISTRECEIVE_FAIL,
    PRODUCT_LISTRECEIVE_REQUEST,
    PRODUCT_LISTRECEIVE_SUCCESS,
} from "../Constants/productConstants";
import { logout } from "./userActions";
import { serverLink } from "../../App";
//const serverLink = "http://localhost:5000"
const second = 1000;
const minute = second * 60;
const day = minute * 60 * 24;
export const listProductReceive = () => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_LISTRECEIVE_REQUEST });
        const getListProductReceiveFromLocalStorage = localStorage.getItem(
            "listProductReceive"
        )
            ? JSON.parse(localStorage.getItem("listProductReceive"))
            : null;
        if (
            getListProductReceiveFromLocalStorage &&
            getListProductReceiveFromLocalStorage.data &&
            !(
                Date.now() - getListProductReceiveFromLocalStorage.timestamp >
                getListProductReceiveFromLocalStorage.ctl
            )
        ) {
            console.log("get from localstorage");
            dispatch({
                type: PRODUCT_LISTRECEIVE_SUCCESS,
                payload: getListProductReceiveFromLocalStorage.data,
            });
        }

        const { data } = await axios.get(`${serverLink}/api/productsReceive`);
        dispatch({ type: PRODUCT_LISTRECEIVE_SUCCESS, payload: data });

        const setToLocalStorage = {
            data: data,
            timestamp: Date.now(),
            ctl: day,
        };
        //  saveBigDataToLocalStorage("getNftCollectionsMass",data,{timestamp:Date.now(),ctl:60000},1000000)
        localStorage.setItem(
            "listProductReceive",
            JSON.stringify(setToLocalStorage)
        );
    } catch (error) {
        dispatch({
            type: PRODUCT_LISTRECEIVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
// PRODUCT SEND LIST
export const listProductSend = () => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_LIST_REQUEST });
        const getListProductSendFromLocalStorage = localStorage.getItem(
            "listProductSend"
        )
            ? JSON.parse(localStorage.getItem("listProductSend"))
            : null;
        if (
            getListProductSendFromLocalStorage &&
            getListProductSendFromLocalStorage.data &&
            !(
                Date.now() - getListProductSendFromLocalStorage.timestamp >
                getListProductSendFromLocalStorage.ctl
            )
        ) {
            console.log("get from localstorage");
            dispatch({
                type: PRODUCT_LIST_SUCCESS,
                payload: getListProductSendFromLocalStorage.data,
            });
        }
        const { data } = await axios.get(`${serverLink}/api/productsSend`);

        const setToLocalStorage = {
            data: data,
            timestamp: Date.now(),
            ctl: day,
        };
        localStorage.setItem(
            "listProductSend",
            JSON.stringify(setToLocalStorage)
        );
        dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

// SINGLE PRODUCT
export const listProductDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_DETAILS_REQUEST });
        const getListProductDetailsFromLocalStorage = localStorage.getItem(
            "listProductDetails"
        )
            ? JSON.parse(localStorage.getItem("listProductDetails"))
            : null;
        if (
            getListProductDetailsFromLocalStorage &&
            getListProductDetailsFromLocalStorage.data &&
            !(
                Date.now() - getListProductDetailsFromLocalStorage.timestamp >
                getListProductDetailsFromLocalStorage.ctl
            )
        ) {
            console.log("get from localstorage");
            dispatch({
                type: PRODUCT_DETAILS_SUCCESS,
                payload: getListProductDetailsFromLocalStorage.data,
            });
        }
        const { data } = await axios.get(`${serverLink}/api/products/${id}`);

        const setToLocalStorage = {
            data: data,
            timestamp: Date.now(),
            ctl: day,
        };
        localStorage.setItem(
            "listProductDetails",
            JSON.stringify(setToLocalStorage)
        );

        dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

// PRODUCT REVIEW CREATE
export const createProductReview =
    (productId, review) => async (dispatch, getState) => {
        try {
            dispatch({ type: PRODUCT_CREATE_REVIEW_REQUEST });

            const {
                userLogin: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            await axios.post(
                `${serverLink}/api/products/${productId}/review`,
                review,
                config
            );
            dispatch({ type: PRODUCT_CREATE_REVIEW_SUCCESS });
        } catch (error) {
            const message =
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message;
            if (message === "Not authorized, token failed") {
                dispatch(logout());
            }
            dispatch({
                type: PRODUCT_CREATE_REVIEW_FAIL,
                payload: message,
            });
        }
    };
