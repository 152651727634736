import React from "react";

const privacyPolicy=()=>{
return (<div className="content">
<div className="container">
  <div className="content-top">
    <h1 className="title">Polityka prywatności</h1>
  </div>
  <div className="content-wrapper bg-opacity">
    <p>&nbsp;1. Jakie dane osobowe użytkowników mogą być wykorzystywane przez firmę?</p>
    <p>Logując się do portalu jako użytkownik, należy pamiętać o zarejestrowaniu się, wskazując na to, że:</p>
    <ul>
      <li>adres e-mail skrzynki pocztowej;</li>
      <li>numer karty w Twoim banku, abyś mógł dokonywać wpłat i wypłat;</li>
      <li>numer e-portfela.</li>
    </ul>
    <p>Takie informacje o klientach są zapisywane na portalu i pozostają dostępne do wykorzystania przez cały okres współpracy z firmą.</p>
    <p>2. Jak wykorzystywane są osobiste dane klientów?</p>
    <p>Dane osobowe, które podajesz podczas rejestracji w portalu są przechowywane w serwisie i mogą być wykorzystane do</p>
    <ul>
      <li>ułatwienie sprawnego działania portalu i jego aplikacji, uzyskanie pełnego obrazu funkcjonowania usług portalu w celu jakościowego świadczenia usług na rzecz klientów;</li>
      <li>pomoc w skutecznej realizacji działań działu technicznego;</li>
      <li>badać niedociągnięcia i problemy w funkcjonowaniu portalu oraz podnosić jakość usług świadczonych przez firmę;</li>
      <li>informowania klientów o promocjach i innych informacjach, np. o nowych usługach, wygodnych aplikacjach itp. za pośrednictwem newsletterów e-mail;</li>
      <li>do kontaktu klienta z administracją portalu w celu szybkiego reagowania na świadczone usługi i ogólnie na funkcjonowanie zasobu;</li>
      <li>szybko reagować, aby pomóc klientowi w przypadku zagrożenia oszustwem.</li>
    </ul>
    <p>3. Kto może korzystać z danych indywidualnych klientów?</p>
    <p>Informacje wprowadzone do serwisu podczas rejestracji nie są nikomu ujawniane, chyba że prawo wymaga inaczej. Na przykład informacje o klientach mogą być ujawnione innym osobom na żądanie sądu lub organów ścigania, w przypadku zmiany organizacji lub gdy firma zostanie sprzedana.</p>
    <p>4. W jaki sposób firma wykorzystuje pliki cookies?</p>
    <p>4.1 Do czego służą ciasteczka?</p>
    <p>Informacje o wszystkich działaniach użytkownika w Internecie (wprowadzanie informacji o sobie, przeglądanie zawartości portali itp.) są tymczasowo przechowywane w plikach cookies, które mają niewielki rozmiar i znajdują się na urządzeniu końcowym użytkownika. Dzięki działaniu tych plików użytkownik, który wielokrotnie odwiedza portal, nie wpisuje drugi raz swoich danych osobowych, ponieważ system zapamiętał wszystkie jego działania. Pracownicy portalu, kierując się informacjami otrzymanymi z plików cookies, mogą określić priorytety użytkowników, a tym samym dostosować menu strony, usługi i oferty do wygodniejszego korzystania.</p>
    <p>Rodzaje plików cookies:</p>
    <ul>
      <li>pliki analityczne (służą do uwierzytelniania użytkowników i ogólnego zbierania informacji o nich, co obejmuje poruszanie się po portalach i przeglądanie określonych treści);</li>
      <li>niezbędne pliki (dają użytkownikom dostęp do usług i aplikacji portalu, identyfikują oprogramowanie i przeglądarkę używaną do dostępu do sieci itp;)</li>
      <li>pliki funkcyjne (zapisują wybrane ustawienia użytkownika, ułatwiając tym samym interakcję z portalami sieciowymi);</li>
      <li>Pliki stron trzecich (pomoc w analizie parametrów wydajnościowych serwisu, np. ile osób i jak często korzysta z usług portalu lub tylko przegląda treści, które reklamy cieszą się większym powodzeniem).</li>
    </ul>
    <p>4.2 Jak długo pliki cookie pozostają na komputerze?</p>
    <p>Informacje w plikach tekstowych są przechowywane na komputerze przez długi czas. Czas przechowywania zależy od rodzaju pliku cookie. Po wykonaniu określonych zadań pliki te są automatycznie usuwane z komputera.</p>
    <p>4.3 Kto jeszcze może korzystać z plików cookies?</p>
    <p>Wyszukiwarka Yandex i osoby trzecie mogą wykorzystywać dane klientów zawarte w plikach cookies do własnych celów, z wyjątkiem wykorzystania danych poza portalem, ponieważ musi to być określone w specjalnej umowie.</p>
    <p>Gdy odwiedzający po raz pierwszy odwiedza portal, automatycznie otrzymuje komunikat z prośbą o zaakceptowanie lub odrzucenie stosowania plików cookies. Po wyrażeniu zgody użytkownik może zrezygnować z tej oferty, samodzielnie usuwając te pliki ze swojej przeglądarki.</p>
    <p>Jeśli użytkownik będzie musiał ponownie wejść na stronę internetową, zostanie poproszony o ponowne użycie pliku cookie. Jeśli komunikat zostanie zignorowany, odwiedzający może nie być w stanie w pełni korzystać z funkcji portalu.</p>
    <p>Aby uzyskać dostęp do plików tekstowych na urządzeniu użytkownika, Yandex stosuje tagi pikselowe. Możesz je wykorzystać do:</p>
    <ul>
      <li>monitorować ruchy użytkowników w Internecie;</li>
      <li>analizować informacje dotyczące prawidłowego funkcjonowania strony internetowej.</li>
    </ul>
    <p>5. Gwarantowane bezpieczeństwo dla użytkowników</p>
    <p>&nbsp;Pracownicy administracji portalu dysponują zestawem środków służących ochronie danych użytkowników. Są one zaprojektowane tak, aby w porę wykryć nieuczciwą działalność, co pomaga ograniczyć rozpowszechnianie informacji o użytkowniku wśród innych osób.</p>
    <p>Mimo to, nie ma całkowitej gwarancji przed zhakowaniem kont przez atakujących, ponieważ wykorzystują oni coraz więcej technik do osiągnięcia swoich celów. Użytkownicy muszą chronić swoje konta poprzez posiadanie silnych haseł i nie udostępnianie ich nikomu. Jeśli zauważysz, że ktoś loguje się na Twoje konto, poinformuj o tym jak najszybciej pracowników działu pomocy technicznej, aby chronić swoje dane.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p></div>
</div>      

</div>
)
}
export default privacyPolicy;